// Encapsulates API calls into a hook

import {useEffect, useState} from "react";
import {Client} from "./client";
import {useDebounce} from "../utilities/debounce";
import {Container} from "aurelia-dependency-injection";
import {FlashService} from "../flash/flash-service";

const client = Client.getInstance();

/**
 * @param endpoint: string
 * @param params: object
 * @returns {{data: unknown, loading: boolean}}
 */
export const useApiFetch = (endpoint, params = {}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const paramsText = JSON.stringify(params);

    useEffect(
        () => {
            const paramEntries = Object.entries(params);
            const controller = new AbortController();

            if (paramEntries.length) {
                const query = new URLSearchParams();
                for (const [key, value] of paramEntries) {
                    query.set(key, "object" === typeof value ? JSON.stringify(value) : "" + value);
                }
                endpoint += "?" + query.toString();
            }

            client.get(endpoint, null, {signal: controller.signal}).then(data => {
                setData(data);
            }).finally(() => {
                setLoading(false);
            });

            return () => controller.abort();
        },
        [endpoint, paramsText]
    );

    return {data, loading};
};

export const useApiPost = (endpoint, body = {}, additionalDeps) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(true);
    const debounced = useDebounce([endpoint, body, additionalDeps]);

    useEffect(
        () => {
            const controller = new AbortController();

            if (!loading) {
                setUpdating(true);
            }

            client.post(endpoint, body, {signal: controller.signal})
                .then(
                    ({data, ok}) => {
                        if (ok) {
                            setData(data);
                        } else {
                            // @todo error handling, validation response etc.
                        }
                    },
                    error => console.error(error)
                )
                .finally(() => {
                    setLoading(false);
                    setUpdating(false);
                });

            return () => controller.abort();
        },
        [debounced]
    );

    return {data, loading, updating};
};

const searchCache = new Map;

export const useApiSearch = (endpoint, search, conditions = {}, additionalParams = {}, forceSearch = false) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const debouncedSearch = useDebounce(search);
    const conditionsText = JSON.stringify(conditions);
    const additionalParamsText = JSON.stringify(additionalParams);

    useEffect(
        () => {
            const cacheKey = [endpoint, search, conditionsText, additionalParamsText].join("\0");

            if (searchCache.has(cacheKey)) {
                setItems(searchCache.get(cacheKey));
                return;
            }

            if (!forceSearch && (!debouncedSearch || "" === debouncedSearch)) {
                setItems([]);
                setLoading(false);
                return;
            }

            const params = new URLSearchParams();
            const controller = new AbortController();

            if ("" !== debouncedSearch) {
                conditions = Object.assign({search: debouncedSearch}, conditions);
            }

            if (Object.keys(conditions).length) {
                additionalParams = Object.assign({conditions: JSON.stringify(conditions)}, additionalParams);
            }

            for (const [key, value] of Object.entries(Object.assign(additionalParams))) {
                params.set(key, "object" === typeof value ? JSON.stringify(value) : "" + value);
            }

            setLoading(true);
            client.get(endpoint + "?" + params.toString(), null, {signal: controller.signal})
                .then(
                    result => {
                        const items = Array.isArray(result) ? result : (result?.items ?? []);
                        setItems(items);
                        setLoading(false);
                        searchCache.set(cacheKey, items);
                    },
                    e => {
                        if (e instanceof DOMException && "AbortError" === e.name) {
                            return;
                        }
                        console.error(e);
                        Container.instance.get(FlashService).error(e?.data?.localizedMessage ?? e?.data?.message ?? e?.message ?? "Ein Fehler ist aufgetreten");
                        setLoading(false);
                    }
                );

            return () => controller.abort();
        },
        [debouncedSearch, conditionsText, additionalParamsText]
    );

    return {items, loading};
}

/**
 * @param modelId: string
 * @param search: string
 * @param conditions: object
 * @returns {{loading: boolean, items: *[]}}
 */
export const useModelSearch = (modelId, search, conditions = {}) => useApiSearch(
    "search/" + modelId,
    search,
    {$and: [Object.assign({archived: {$ne: true}}, conditions)]}
);

/**
 * @param modelId: string
 * @param search: string
 * @param conditions: object
 * @param value: object
 * @returns {{loading: boolean, items: *[]}}
 */
export const useModelChoiceSearch = (modelId, search, conditions, value) => useApiSearch(
    "model/choice/" + modelId,
    search,
    conditions,
    value?.id ? {initialValues: [{id: value.id, modelId: value.modelId}]} : undefined,
    true
);

export default useApiFetch;
