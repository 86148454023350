import {inject} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {Client} from "../../api/client";
import {FlashService} from "../../flash/flash-service";
import {DialogService} from "aurelia-dialog";
import {Confirm} from "../../dialog/confirm";
import {EventAggregator} from "aurelia-event-aggregator";

@inject(Client, FlashService, DialogService, EventAggregator)
export class DeleteActionHandler extends ActionHandlerInterface {

    constructor(client, flash, dialog, ea) {
        super();

        this.client = client;
        this.flash = flash;
        this.dialog = dialog;
        this.ea = ea;
    }

    getActionType = () => 'delete';

    getReturnContext = (config, context) => ({
        message: 'sio.delete.confirm',
        okMessage: 'sio.delete',
        cancelMessage: 'sio.cancel',
        modelId: config.modelId,
        updateModelIds: config.updateModelIds,
        ...context
    });

    getAction = returnContext => () => this.dialog
        .open({viewModel: Confirm, model: returnContext})
        .whenClosed(({wasCancelled}) => wasCancelled ? false : this.client
            .remove(
                returnContext.modelId + '/' +
                (Array.isArray(returnContext.id) ? returnContext.id.join(',') : returnContext.id)
            )
            .then(
                data => {
                    this.ea.publish(
                        'sio_form_post_submit',
                        {config: {modelId: returnContext.modelId}}
                    );

                    for (const modelId of returnContext.updateModelIds ?? []) {
                        this.ea.publish('sio_form_post_submit', {config: {modelId}});
                    }

                    this.flash.response(data, 'success', 'delete.successful');

                    return true;
                },
                error => {
                    console.error(error);
                    this.flash.response(error, 'error', 'delete.error');

                    return Promise.reject();
                }
            )
        );
}
