import {inject} from "aurelia-framework";
import {Router} from "aurelia-router";
import {ActionHandlerInterface} from "./action-handler.interface";
import {DialogView} from "../../view/dialog-view";
import {DialogService} from "aurelia-dialog";
import {Client} from "../../api/client";

@inject(
    DialogService,
    Client,
    Router
)
export class DuplicateActionHandler extends ActionHandlerInterface
{
    constructor(
        dialog,
        client,
        router
    ) {
        super();

        this.dialog = dialog;
        this.client = client;
        this.router = router;
    }

    getActionType()
    {
        return 'duplicate';
    }

    getReturnContext(config, context)
    {
        return Object.assign(
            {
                id: config.id,
                formId: config.formId,
                title: config.title,
                bulk: config.bulk || false,
                redirect: config.redirect != null ? config.redirect : true
            },
            context
        );
    }

    getAction(returnContext)
    {
        return () => {

            returnContext.client = this.client;
            returnContext.router = this.router;

            return this.dialog.open(
                {
                    viewModel: DialogView,
                    model: {
                        formContext: {
                            workflowId: returnContext.workflowId,
                            //TODO Problem with a couple of forms, is the wrong object id here?
                            id: returnContext.id,
                            modelId: returnContext.modelId,
                            contextObjectRef: returnContext.contextObjectRef,
                            bindValues: returnContext.bindValues,
                        },
                        submitHandler: this.duplicateFormSubmit.bind(returnContext),
                        duplicateObjectId: returnContext.id,
                        duplicateFormId: returnContext.formId,
                        title: returnContext.title,
                        content: '<sio-form config.bind="params.duplicateFormId" ' +
                        'context-object-ref.bind="params.formContext"' +
                        'disable-local-storage.bind="true" ' +
                        'data.bind="params.duplicateObjectId" ' +
                        'submit.bind="params.submitHandler" ' +
                        'sio-post-submit.trigger="finishContext()"></sio-form>',
                    }
                }
            ).whenClosed();
        };
    }

    duplicateFormSubmit(value)
    {
        console.log('Submit duplicate', this, value);
        //This is returnContext here
        return this.client.post(this.formId + '/duplicate/' + this.id, value.object).then((response) => {

            if (response.data && response.data.id && this.redirect) {

                let modelId = response.data.modelId.split('/');

                this.router.navigateToRoute('view', {moduleId: modelId[0], viewId: modelId[1], id: response.data.id});
            }

            return response;
        });
    }
}
