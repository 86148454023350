import {useMemo} from "react"
import {useSelector} from "react-redux"
import {createSelector, createSlice} from "@reduxjs/toolkit"
import {accountingBaseDataLoaded} from "./accounting-api"

const slice = createSlice({
    name: "accounting/state",
    initialState: {
        states: {},
        actions: {},
        organizations: {},
        periods: {}
    },
    reducers: {
        setCurrentPeriod: {
            prepare: (organizationId, periodId) => ({payload: {organizationId, periodId}}),
            reducer(state, {payload: {organizationId, periodId}}) {
                state.organizations[organizationId].current = periodId
            }
        }
    },
    extraReducers: builder => builder
        .addMatcher(accountingBaseDataLoaded, (state, {payload: {states, actions, organizations}}) => {
            state.states = states
            state.actions = actions
            state.organizations = organizations
            state.periods = Object.assign({}, ...Object.values(organizations).map(({periods}) => periods))
        })
})

export const {
    setCurrentPeriod
} = slice.actions

const selectAccountingState = createSelector(state => state?.accounting?.state, state => state)
const selectOrganization = id => createSelector(selectAccountingState, state => state.organizations[id])
const selectOrganizationPeriods = id => createSelector(selectOrganization(id), organization => organization?.periods)

const state = slice.reducer

export default state
export const selectAccountingActions = createSelector(selectAccountingState, ({actions}) => actions)
export const selectAccountingStates = createSelector(selectAccountingState, ({states}) => states)
export const selectCurrentPeriod = organizationId => createSelector(selectOrganization(organizationId), organization => organization?.current)
export const selectPeriod = id => createSelector(selectAccountingState, ({periods}) => periods[id])
export const selectOrganizations = createSelector(
    selectAccountingState,
    ({organizations}) => Object.entries(organizations).map(([id, {label}]) => ({id, label}))
)

export function useAccountingStates() {
    const states = useSelector(selectAccountingStates)

    return useMemo(
        () => Object.entries(states ?? {}).map(([id, state]) => ({id, ...state})),
        [states]
    )
}

export function usePeriodsByOrganization(organizationId) {
    const periods = useSelector(selectOrganizationPeriods(organizationId))

    return useMemo(
        () => Object.entries(periods ?? {})
            .map(([id, period]) => ({id, ...period}))
            .sort(({startDate: a}, {startDate: b}) => b.localeCompare(a)),
        [periods]
    )
}
