import {inject} from 'aurelia-framework';
import {Client} from './client';
import {UserClient} from "./user-client.js";

@inject(Client, UserClient)
export class PermissionClient {

    constructor(client, userClient) {
        this.client = client;
        this.userClient = userClient;
        this.cache = new Map;
    }

    matchesPermissionCondition(object, conditions) {

        // avoid useless calls to core
        if (!conditions || !Object.keys(conditions).length) {
            return true;
        }

        const params = new URLSearchParams();
        params.set("conditions", JSON.stringify(conditions));

        if (object) {
            if ("object" === typeof object && object.hasOwnProperty("id") && object.hasOwnProperty("modelId")) {
                params.set("object[id]", object.id);
                params.set("object[modelId]", object.modelId);
            } else if ("@user" === object) {
                params.set("object[id]", this.userClient.user.id);
                params.set("object[modelId]", this.userClient.user.modelId);
            } else {
                throw new Error("Can not check permission on object of unsupported type", object);
            }
        }

        const paramsString = params.toString();

        if (this.cache.has(paramsString)) {
            return Promise.resolve(this.cache.get(paramsString))
        }

        const promise = this.client
            .post('permission/match-object-conditions?' + paramsString)
            .then(result => result.data);

        this.cache.set(paramsString, promise);

        return promise;
    }
}

export default PermissionClient;
