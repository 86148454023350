import React, {useContext} from "react";
import {useStyles} from "./styles"
import {Context} from "./context"
import Fields from "./fields"
import AureliaContext from "../../../utilities/aurelia-context";

export function Table() {
    const {table} = useStyles()
    const {currencyValueConverter, dateValueConverter} = useContext(AureliaContext)
    const {fields, reports, payAmount} = useContext(Context)

    return (
        <table className={"table table-striped table-condensed " + table}>
            <thead>
            <tr>
                <th>Zeile</th>
                <th>Name</th>
                <th>Kz.</th>
                {reports.map(date => <th key={date.toString()}>{dateValueConverter.toView(date)}</th>)}
                <th>einzutragen</th>
            </tr>
            </thead>

            {fields.filter(({level}) => !level).map(({identifier, name}) => (
                <tbody key={identifier}>
                <tr>
                    <th colSpan={4 + reports.length}>{identifier + ". " + name}</th>
                </tr>

                <Fields parent={identifier}/>
                </tbody>
            ))}

            {payAmount?.amount && (
                <tfoot>
                <tr>
                    <th colSpan={4 + reports.length}>{payAmount.amount < 0 ? 'Vom Finanzamt zu erstatten' : 'Ans Finanzamt zu zahlen'}</th>
                    <th>{currencyValueConverter.toView(payAmount)}</th>
                </tr>
                </tfoot>
            )}
        </table>
    )
}

export default Table
