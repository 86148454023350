import {bindable, customElement, inject, noView} from "aurelia-framework"
import React from "react"
import {render} from "react-dom"
import AccountingProvider from "../accounting-provider"
import {ContextProvider} from "./vat-statement/context"
import Table from "./vat-statement/table"

@customElement('sio-accounting-vat-statement')
@noView()
@inject(Element)
export class VatStatement {
    @bindable statement

    constructor(container) {
        this.container = container
    }

    attached() {
        render(
            (
                <ContextProvider statement={this.statement}>
                    <AccountingProvider context="vat-statement">
                        <Table/>
                    </AccountingProvider>
                </ContextProvider>
            ),
            this.container
        )
    }

    statementChanged() {
        this.attached()
    }
}

export default VatStatement
