import {createUseStyles} from "react-jss";
import Color from "color";

const btnColor = (bg, fg = '#fff') => ({
    '&, &.rs-btn-default.rs-btn-icon.rs-btn-icon-with-text > .rs-icon': {
        backgroundColor: bg,
        color: fg,
        '&:hover, &:active, &:focus': {
            backgroundColor: Color(bg).lighten(.25).hex(),
            color: fg,
        }
    },
    '&.rs-btn-default.rs-btn-icon.rs-btn-icon-with-text.rs-btn-disabled': {
        '&, & > .rs-icon': {
            backgroundColor: Color(bg).lighten(.5).hex(),
            color: fg
        }
    }
})

export const accountingStyles = {
    drawer: {
        transition: "height .3s ease-in-out",
    },
    danger: btnColor('#dc3545'),
    primary: btnColor('#007bff'),
    secondary: btnColor('#6c757d'),
    dateRange: {
        backgroundColor: "#fff",

        '&.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value': {
            color: "inherit",
            paddingLeft: ".25em",
            paddingRight: ".25em",
        }
    },
    actions: {
        "& button.rs-btn-block": {
            maxWidth: "20rem"
        }
    },
    table: {
        "& .rs-table-cell .rs-table-cell-content": {
            whiteSpace: "nowrap",
            padding: "0 8px",
            overflowX: "hidden",
            textOverflow: "ellipsis",
        },

        "& .rs-table-cell-group > .rs-table-cell-first, & .rs-table-cell-header > .rs-table-cell-first": {
            margin: 0,
            padding: 0,

            "& > .rs-table-cell-content": {
                margin: 0,
                padding: 0,
                display: "inline-flex",
                maxHeight: "46px",
                alignItems: "center",

                "& > *": {
                    flex: "1 1 auto",
                    width: "22px",

                    "& > .rs-checkbox": {
                        marginLeft: "-4px",
                        marginTop: "-2px",
                        overflow: "hidden",
                        transform: "scale(.8)"
                    }
                }
            }
        },

        "& .rs-table-cell-group > .rs-table-cell-first > .rs-table-cell-content > *": {
            textAlign: "center",
        },

        "& .rs-table-row-expanded": {
            maxWidth: "98vw",
            fontSize: "90%",
            maxHeight: "50vh",
            overflowY: "auto",

            "& > .rs-flex-box-grid": {
                height: "100%",
                    "& > .rs-flex-box-grid-item": {
                    height: "100%",

                    // booking details
                    "&:first-child": {
                        marginBottom: "1rem",

                        "& > .rs-flex-box-grid > .rs-flex-box-grid-item:nth-child(odd)": {
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            "&:after": {
                                content: '":"'
                            }
                        }
                    },

                    // booking parts and actions
                    "&:last-child > .rs-flex-box-grid": {
                        height: "100%",
                        maxWidth: "40vw",
                        alignContent: "space-between",
                        justifyContent: "flex-end",

                        "& > .rs-flex-box-grid-item": {
                            textAlign: "right",
                            maxWidth: "40vw",

                            // booking parts
                            "&:first-child > .rs-flex-box-grid > .rs-flex-box-grid-item": {
                                "&:nth-child(1), &:nth-child(2), &:nth-child(3)": {
                                    fontWeight: "bold"
                                },
                                "&:nth-child(4n)": {
                                    textAlign: "left",
                                    paddingLeft: ".5rem"
                                }
                            },

                            // actions
                            "&:last-child": {
                                marginTop: "1.5rem",
                            }
                        }
                    }
                }
            }
        }
    },

    "@media screen and (max-width: 639px)": {
        table: {
            "& .rs-table-row-expanded > .rs-flex-box-grid": {
                "&, & > .rs-flex-box-grid-item, & > .rs-flex-box-grid-item:last-child > .rs-flex-box-grid": {
                    height: "inherit",
                    display: "block"
                },
                "& > .rs-flex-box-grid-item:last-child > .rs-flex-box-grid": {
                    maxWidth: "inherit",

                    "& > .rs-flex-box-grid-item": {
                        maxWidth: "inherit",

                        "&:last-child": {
                            textAlign: "center",
                            marginTop: "2.5rem"
                        }
                    }
                }
            }
        }
    },

    "@media screen and (min-width: 1200px)": {
        table: {
            "& .rs-table-row-expanded": {
                fontSize: "inherit"
            }
        }
    },
}

export const useAccountingStyles = createUseStyles(accountingStyles)
export default useAccountingStyles
