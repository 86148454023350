import {createUseStyles} from "react-jss";

const styles = {
    table: {
        textAlign: "left",
        "& > tbody > tr": {
            "& > th": {
                backgroundColor: "#c0c0c0"
            },
            "& > td": {
                textAlign: "right",
                "&[data-level]": {whiteSpace: "nowrap"},
                "&[data-level]:before": {content: "'╰ '"},
                "&[data-level='1']:before": {display: "none"},
                "&[data-level='2']": {paddingLeft: "1em"},
                "&[data-level='3']": {paddingLeft: "2em"},
                "&:nth-child(1), &:nth-child(2)": {
                    textAlign: "left"
                },
                "&:last-child": {
                    backgroundColor: "#e0e0e0",
                    fontWeight: "bolder"
                },
            },
        },
        "& tfoot th": {
            borderTop: "4px double #999999"
        }
    }
}
export const useStyles = createUseStyles(styles)
