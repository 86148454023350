import * as _ from "lodash";

export class DynamicConditionsBuilder
{
    /**
     * Replace conditions @-prefixed parameters with values from entity.
     *
     * @param conditions {Array} Array of conditions
     * @param entity {Object} Some entity, perhaps even assigned to form
     *
     * @return {Array} Conditions with values instead of @-prefixed parameters
     */
    buildDynamicConditions(conditions, entity)
    {
        if (_.isEmpty(conditions)) {
            return [];
        }

        let generatedConditions = _.cloneDeep(conditions);

        this.substituteModelValues(
            generatedConditions,
            entity,
            this.getModelReferenceParametersNames(entity)
        );

        return generatedConditions;
    }

    /**
     * Get list of all unique @-prefixed parameters in conditions.
     *
     * @param conditions {Array} Array of conditions to apply for filtering
     * @param entity {Object} Some entity, perhaps even assigned to form
     *
     * @return {Array} Array of all unique @-prefixed parameters from conditions
     */
    getAllDynamicParametersFromConditions(conditions, entity)
    {
        let referenceParametersNames = this.getModelReferenceParametersNames(entity);

        // Use recursive descent to catch all parameters

        let _getAllDynamicParameters = (conditions, result) => {
            _.each(conditions, (conditionValue) => {
                if (_.isArray(conditionValue) || _.isObject(conditionValue)) {
                    // If conditions have nested conditions, traverse nested conditions too

                    result = _getAllDynamicParameters(conditionValue, result);
                } else if (referenceParametersNames.indexOf(conditionValue) > -1) {

                    result.push(conditionValue);
                }
            });

            return result;
        };

        return _getAllDynamicParameters(conditions, []);
    }

    /**
     * Get all parameters names from entity and make them @-prefixed.
     *
     * @param entity {Object} Some entity, perhaps even assigned to form
     */
    getModelReferenceParametersNames(entity)
    {
        return _.map(
            _.keys(entity),
            propertyName => this.referencePropertyName(propertyName)
        );
    }

    /**
     * Replace @-prefixed parameters in conditions to values of properties from model.
     *
     * @param conditions {Array} Array of conditions to apply for filtering
     * @param entity {Object} Some entity, perhaps even assigned to form
     * @param modelMapping {Array} Array of property names with @ prefixes
     */
    substituteModelValues(conditions, entity, modelMapping)
    {
        _.each(conditions, (conditionValue, conditionKey) => {
            if (_.isArray(conditionValue) || _.isObject(conditionValue)) {
                // If conditions have nested conditions, traverse nested conditions too

                this.substituteModelValues(conditionValue, entity, modelMapping);
            } else if (modelMapping.indexOf(conditionValue) > -1) {
                // Replace @property with property value from model

                let normalizedPropertyName = this.dereferencePropertyName(conditionValue);

                conditions[conditionKey] = entity[normalizedPropertyName];
            } else if (conditionValue === "@null") {
                // Replace @null with null value

                conditions[conditionKey] = null;
            }
        });
    }

    // Remove @ symbol from the beginning of property name
    dereferencePropertyName(propertyName)
    {
        return propertyName.substr(1);
    }

    // Add @ symbol to the beginning of property name
    referencePropertyName(propertyName)
    {
        return "@" + propertyName;
    }
}