import React, {Fragment, useContext} from "react"
import AureliaContext from "../../../utilities/aurelia-context"
import Context from "./context"
import Field from "./field"

export function Fields({parent}) {
    const {currencyValueConverter} = useContext(AureliaContext)
    const {fields} = useContext(Context)
    const filtered = fields.filter(f => f.parent === parent)

    return filtered.map(({identifier, level, name, code, amount, reported}) => (
        <Fragment key={identifier}>
            <tr>
                <td data-level={level}>{identifier}</td>
                <td>{name}</td>
                <td>{code && <Field field={code}/>}</td>
                {reported.map((amount, idx) => ("object" === typeof amount) && (
                    <td key={idx}>{currencyValueConverter.toView(amount)}</td>
                ))}
                <td>{("object" === typeof amount) && currencyValueConverter.toView(amount)}</td>
            </tr>

            <Fields parent={identifier}/>
        </Fragment>
    ))
}

export default Fields
