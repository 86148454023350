import {LogManager} from 'aurelia-framework';

export class LogRequestInterceptor
{
    constructor()
    {
        this.logger = LogManager.getLogger('api');
    }

    request(request)
    {
        this.logger.debug('Request', request);

        return request;
    }

    requestError(error)
    {
        this.logger.error('Request error', error);

        throw error;
    }

    response(response)
    {
        this.logger.debug('Response', response);

        return response;
    }

    responseError(error)
    {
        this.logger.error('Response error', error);

        throw error;
    }
}
