import {bindable, customElement, inject} from 'aurelia-framework';
import {Client} from '../api/client';
import {throttle} from 'lodash';

@inject(
    Client,
)
export class Activity {

    constructor(
        client,
    ) {
        this.client = client;

        this.debounced = throttle(this._internalUpdate, 10000);
    }

    _internalUpdate(type, identifier, objectId)
    {
        try {
            this.client.put('user/activity/' + type + '/' + identifier + '/' + objectId)
                .catch(() => {});
        } catch (e) {

        }
    }

    updateActivity(type, identifier, objectId) {

        this.debounced(type, identifier, objectId);
    }

    updateInitial(type, identifier, objectId) {
        try {
            this.client.put('user/initial/' + type + '/' + identifier + '/' + objectId)
                .catch(() => {});
        } catch (e) {

        }
    }
}
