import {createContext} from "react"

export const Context = createContext({})
export default Context

/**
 * @param {{
 *   id: string,
 *   payAmount: Money,
 *   reports: {version: int, date: Date}[],
 *   fields: {
 *     identifier: string,
 *     parent: ?string,
 *     code: ?string,
 *     name: string,
 *     amount: Money,
 *     reported: {version: int, amount: Money}[],
 *   }[]
 * }} statement
 * @param {ReactNode} children
 */
export function ContextProvider({statement, children}) {
    const fields = new Map(statement.fields.map(f => [f.identifier, structuredClone(f)]))
    const reports = statement.reports.toSorted(({version: a}, {version: b}) => a - b)
    const versions = reports.map(({version}) => version)

    for (const field of fields.values()) {
        field.level = 0
        field.reported = versions.map(v => field.reported.find(({version}) => version === v)?.amount ?? null)
        // noinspection StatementWithEmptyBodyJS
        for (let parent = field.identifier; !!(parent = fields.get(parent)?.parent); ++field.level) {
        }
    }

    return (
        <Context.Provider value={{
            statement: statement.id,
            payAmount: statement.payAmount,
            fields: Array.from(fields.values())
                .sort(({identifier: a}, {identifier: b}) => a.localeCompare(b)),
            reports: reports.map(({date}) => date)
        }}>
            {children}
        </Context.Provider>
    )
}
