import {inject, LogManager} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {DialogView} from "../../view/dialog-view";
import {DialogService} from "aurelia-dialog";
import {Router} from "aurelia-router";
import {EventAggregator} from "aurelia-event-aggregator";

const logger = LogManager.getLogger('action-handler');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(
    DialogService,
    Router,
    EventAggregator
)
export class ViewActionHandler extends ActionHandlerInterface {
    constructor(
        dialog,
        router,
        ea
    ) {
        super();

        this.dialog = dialog;
        this.router = router;
        this.ea = ea;
    }

    getActionType() {
        return 'view';
    }

    getReturnContext(config, context) {
        return Object.assign(
            {
                moduleId: config.viewId.split('/')[0],
                viewId: config.viewId.split('/')[1],
                bindValues: config.bindValues,
                modal: config.modal,
                title: config.title,
                bulk: config.bulk || false,
                updateModelIds: config.updateModelIds,
            },
            context,
            config.viewContext ?? {}
        );
    }

    getAction(returnContext) {
        return () => {
            // noinspection JSCheckFunctionSignatures
            logger.debug('Performing `view` action with context ', returnContext);

            if (returnContext.modal) {
                return this.dialog
                    .open({viewModel: DialogView, model: returnContext})
                    .whenClosed(({wasCancelled, output}) => {
                        for (const modelId of returnContext.updateModelIds ?? []) {
                            this.ea.publish('sio_form_post_submit', {config: {modelId: modelId}});
                        }

                        return wasCancelled ? Promise.reject() : Promise.resolve(output);
                    });
            } else {
                this.router.navigateToRoute('view', returnContext);
            }
        };
    }
}
