import {inject, NewInstance} from "aurelia-framework";
import {HttpClient, json} from "aurelia-fetch-client";
import {AureliaConfiguration} from "aurelia-configuration";

@inject(NewInstance.of(HttpClient), AureliaConfiguration)
export class OAuthClient {

    constructor(httpClient, appConfig) {
        this.client = httpClient;
        this.appConfig = appConfig;

        this.client.configure(config => {
            config
                .useStandardConfiguration()
                .withBaseUrl(appConfig.get('authUrl'));
        });
    }

    async getToken(username, password) {
        let options = {
            method: 'post',
            body: json({
                grant_type: 'password',
                client_id: this.appConfig.get('clientId'),
                client_secret: this.appConfig.get('clientSecret'),
                username: username,
                password: password
            })
        };

        return this.client.fetch('', options).catch(response => {
            return response.text().then(data => {
                try {
                    response.data = data.length != 0 ? JSON.parse(data) : null;
                } catch (e) {
                    response.data = null;
                }

                throw response;
            });
        }).then(response => response.json());
    }

    async refreshToken(refreshToken) {
        let options = {
            method: 'post',
            body: json({
                grant_type: 'refresh_token',
                client_id: this.appConfig.get('clientId'),
                client_secret: this.appConfig.get('clientSecret'),
                refresh_token: refreshToken
            })
        };

        return this.client.fetch('', options)
            .then(response => response.json())
        ;
    }
}
