import React, {useContext, useEffect, useState} from "react";
import {useVatStatementFieldHistoryQuery} from "../../store/accounting-api";
import useAccountingStyles from "../../styles";
import {Button, Loader, Table} from "rsuite";
import {doDownload} from "../../../utilities/download";
import {EntryDetails} from "../../entry/details";
import SioIcon from "../../../icon/rsuite-icon-font/SioIcon";
import {Context} from "./context";
import AureliaContext from "../../../utilities/aurelia-context";

export function FieldHistory({field}) {
    const {currencyValueConverter, dateValueConverter} = useContext(AureliaContext)
    const {statement} = useContext(Context)
    /** @type {{createdAt: Date, message: string, amount: ?Money, bookings: Booking[]}[]} data */
    const {isFetching, isLoading, data} = useVatStatementFieldHistoryQuery({statement, field})
    const [expanded, setExpanded] = useState([])
    const [loading, setLoading] = useState(false)
    const {table} = useAccountingStyles()

    useEffect(() => {
        setExpanded([])
    }, [isLoading])

    if (isFetching || isLoading) {
        return <Loader/>
    }

    const ids = data.filter(({booking}) => !!booking).map(({booking: {booking}}) => booking)

    return (
        <>
            {ids.length && (
                <Button size="sm" loading={loading} disabled={!ids.length}
                        onClick={() => doDownload("accounting/excel-download", ids, setLoading, "buchhaltung.xlsx")}>
                    <span className="glyphicon glyphicon-export"/>
                </Button>
            )}

            <Table
                className={table}
                data={data}
                rowKey="id"
                autoHeight
                shouldUpdateScroll={false}
                expandedRowKeys={expanded}
                renderRowExpanded={({booking}) => <EntryDetails booking={booking}/>}
                rowExpandedHeight={250}
            >
                <Table.Column width={40}>
                    <Table.HeaderCell/>
                    <Table.Cell>
                        {({id, booking}) => booking?.booking && (
                            <SioIcon
                                icon={(expanded.includes(id) ? "minus" : "plus") + "-square-o"}
                                onClick={() => toggle(id)}
                                style={{cursor: "pointer"}}
                            />
                        )}
                    </Table.Cell>
                </Table.Column>

                <Table.Column sortable width={150}>
                    <Table.HeaderCell>Datum</Table.HeaderCell>
                    <Table.Cell>
                        {({createdAt}) => dateValueConverter.toView(createdAt)}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Ereignis</Table.HeaderCell>
                    <Table.Cell dataKey="message"/>
                </Table.Column>

                <Table.Column align="right" width={100}>
                    <Table.HeaderCell>Betrag</Table.HeaderCell>
                    <Table.Cell>
                        {({amount}) => amount?.currency ? currencyValueConverter.toView(amount) : null}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </>
    )

    function toggle(id) {
        const expandedSet = new Set(expanded)

        if (expandedSet.has(id)) {
            expandedSet.delete(id)
        } else {
            expandedSet.add(id)
        }

        setExpanded(Array.from(expandedSet.values()))
    }
}
export default FieldHistory
