import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit"
import {Container} from "aurelia-dependency-injection"
import {FlashService} from "../../flash/flash-service"
import {setContext} from "./entries-slice"
import {
    accountingBaseDataLoaded,
    accountingBookingLoaded,
    accountingBookingLoading,
    accountingBookingSaved,
    accountingBookingSaveError,
    accountingBookingSaving
} from "./accounting-api"
import moment from "moment";

/**
 * @type {FlashService}
 */
const flash = Container.instance.get(FlashService)

const taxableAccountTypes = Object.freeze([
    'EXPENSE', 'WRITE_DOWNS', 'INCOME', 'RECEIVABLE', 'LIABILITY', 'ASSET', 'ACCRUAL'
])

function isTaxable(state, account) {
    if (!account?.id) {
        return false
    }
    if ("boolean" === typeof account.taxable) {
        return account.taxable
    }
    if ("string" === typeof account.type) {
        return account.taxable = taxableAccountTypes.includes(account.type)
    }
    return account.taxable = false
}

const partAdapter = createEntityAdapter()
const partSelectors = partAdapter.getSelectors()

function checkCanSave(state) {
    state.canRemovePart = 2 < partSelectors.selectTotal(state)
    state.canSave = !!(state.booking.bookDate && state.booking.receiptNumber && state.booking.subject)
    state.crSum = state.drSum = 0
    let drAccounts = 0, crAccounts = 0, taxAccounts = 0

    for (const id of partSelectors.selectIds(state)) {
        let {dr, cr, account} = partSelectors.selectById(state, id)

        if (!!dr && !!cr) {
            state.canSave = false
        }

        if (dr < 0) {
            dr = undefined
            cr = -1 * dr
            partAdapter.updateOne(state, {id, changes: {cr, dr}})
        } else if (cr < 0) {
            dr = -1 * cr
            cr = undefined
            partAdapter.updateOne(state, {id, changes: {dr, cr}})
        }

        state.drSum += 1 * (dr ?? 0)
        state.crSum += 1 * (cr ?? 0)

        if (account?.id) {
            if (/TAX/.test(account.type)) {
                ++taxAccounts
            } else if (dr > 0) {
                ++drAccounts
            } else if (cr > 0) {
                ++crAccounts
            }
        } else {
            if ("object" !== typeof account) {
                partAdapter.updateOne(state, {id, changes: {account: {taxable: false}}})
            }
            state.canSave = false
        }
    }

    if (state.drSum !== state.crSum) {
        state.canSave = false
    }

    state.canSimple = (undefined !== state.simpleBooking.amount)
        && (1 >= drAccounts && 1 >= crAccounts && 1 >= taxAccounts)
}

let generatedId = 0;

function calculateSimpleTax(state) {
    const grossAmount = state.simpleBooking.amount
    const accountTaxable = isTaxable(state, state.simpleBooking.account)
    const contraAccountTaxable = isTaxable(state, state.simpleBooking.contraAccount)
    const percentage = state.taxAccounts[state.organization][state.simpleBooking.tax]?.percentage ?? .0

    if (percentage && grossAmount && (accountTaxable || contraAccountTaxable)) {
        const netAmount = Math.round(grossAmount / (1 + percentage))
        const taxAmount = grossAmount - netAmount
        const taxAccount = {
            id: state.simpleBooking.tax,
            modelId: "accounting/ledger-account",
            type: "TAX",
            taxable: false
        }

        partAdapter.setAll(state, accountTaxable ? [
            {id: ++generatedId, account: state.simpleBooking.account, dr: netAmount},
            {id: ++generatedId, account: state.simpleBooking.contraAccount, cr: grossAmount},
            {id: ++generatedId, account: taxAccount, dr: taxAmount},
        ] : [
            {id: ++generatedId, account: state.simpleBooking.account, dr: grossAmount},
            {id: ++generatedId, account: state.simpleBooking.contraAccount, cr: netAmount},
            {id: ++generatedId, account: taxAccount, cr: taxAmount},
        ])
    } else {
        partAdapter.setAll(state, [
            {id: ++generatedId, account: state.simpleBooking.account, dr: grossAmount},
            {id: ++generatedId, account: state.simpleBooking.contraAccount, cr: grossAmount},
        ])
    }

    state.canSimple = true

    checkCanSave(state)
}

function setPartAmount(state, {payload: {id, cr, dr}}) {
    if (!!cr) {
        partAdapter.updateOne(state, {id, changes: {cr: 1 * cr, dr: undefined}})
    } else {
        partAdapter.updateOne(state, {id, changes: {dr: 1 * dr, cr: undefined}})
    }

    const selectors = partAdapter.getSelectors();
    const ids = selectors.selectIds(state)

    if (ids.length > 1 && id === ids[0] && selectors.selectById(state, ids[1]).percentage) {
        calculatePercentages(state, id)
    } else {
        partAdapter.updateMany(state, ids.map(id => ({id, changes: {percentage: undefined}})))
        checkCanSave(state)
    }
}

function calculatePercentages(state, changedId) {
    const selectors = partAdapter.getSelectors()
    const ids = selectors.selectIds(state)
    const changedIndex = ids.indexOf(changedId)
    const first = selectors.selectById(state, ids[0])
    const [base, useCr] = !!first.dr ? [first.dr, false] : (!!first.cr ? [first.cr, true] : [0, false])

    partAdapter.removeMany(state, ids.slice(changedIndex + 1))

    if (!base) {
        return
    }

    let rest = base, restPercentage = 1.0

    for (const id of selectors.selectIds(state).slice(1)) {
        const percentage = selectors.selectById(state, id).percentage ?? 0.0
        const amount = Math.round(base * percentage)
        rest -= amount
        restPercentage -= percentage

        partAdapter.updateOne(state, {id, changes: {
            dr: useCr ? undefined : amount,
            cr: useCr ? amount : undefined,
        }})
    }

    if (rest || restPercentage) {
        partAdapter.addOne(state, {
            id: ++generatedId,
            account: {},
            percentage: restPercentage,
            [useCr ? "cr" : "dr"]: rest,
        })
    }

    checkCanSave(state)
}

const editorSlice = createSlice(({
    name: "accounting/editor",
    initialState: partAdapter.getInitialState({
        open: false,
        loading: false,
        errors: null,
        tab: "simple",
        taxAccounts: {},
        drSum: 0,
        crSum: 0,
        canSimple: true,
        canSave: false,
        canRemovePart: false,
        booking: {},
        simpleBooking: {},
    }),
    reducers: {
        setTab: {
            prepare: tab => ({payload: tab}),
            reducer(state, {payload: tab}) {
                state.tab = tab
            }
        },
        closeEditor(state) {
            state.loading = false
            state.open = false
            state.id = undefined
            state.errors = undefined
        },
        setBooking: {
            prepare: booking => ({payload: booking}),
            reducer(state, {payload: booking}) {
                state.booking = booking
                checkCanSave(state)
            }
        },
        newBooking: {
            prepare: ({organization, period, stack}) => ({payload: {organization, period, stack}}),
            reducer(state, {payload: {period, stack, organization}}) {
                state.open = true
                state.loading = false
                state.id = undefined
                state.errors = undefined
                state.canSimple = true
                state.canSave = false
                state.canRemovePart = false
                state.drSum = 0
                state.crSum = 0
                state.booking = {
                    receiptNumber: "",
                    period: period ? {id: period, modelId: "accounting/period"} : null,
                    organization: {id: organization, modelId: "organization/organization"},
                    subject: "",
                    costObject: null,
                    reference: null,
                    stack: stack ? {id: stack, modelId: "accounting/stack"} : null
                }
                state.simpleBooking = {}

                const initialState = partAdapter.getInitialState()
                for (const prop in initialState) {
                    state[prop] = initialState[prop]
                }
            }
        },
        changeBookDate: {
            prepare: bookDate => ({payload: bookDate}),
            reducer(state, {payload: date}) {
                state.booking.bookDate = date && "" !== date ? moment(date).format("YYYY-MM-DD") : null
                checkCanSave(state)
            }
        },
        changeReceiptDate: {
            prepare: receiptDate => ({payload: receiptDate}),
            reducer(state, {payload: date}) {
                state.booking.receiptDate = date && "" !== date ? moment(date).format("YYYY-MM-DD") : null
                checkCanSave(state)
            }
        },
        changeReceiptNumber: {
            prepare: receiptNumber => ({payload: receiptNumber}),
            reducer(state, {payload: receiptNumber}) {
                state.booking.receiptNumber = receiptNumber
                checkCanSave(state)
            }
        },
        changeSubject: {
            prepare: subject => ({payload: subject}),
            reducer(state, {payload: subject}) {
                state.booking.subject = subject
                checkCanSave(state)
            }
        },
        changeCostObject: {
            prepare: costObject => ({payload: costObject}),
            reducer(state, {payload: costObject}) {
                state.booking.costObject = costObject
            }
        },
        changeReference: {
            prepare: reference => ({payload: reference}),
            reducer(state, {payload: reference}) {
                state.booking.reference = reference
            }
        },

        // simple editor

        setSimpleBooking: {
            prepare: booking => ({payload: booking}),
            reducer(state, {payload: booking}) {
                const amount = booking.parts.reduce((sum, {dr = 0, cr = 0}) => sum - dr + cr, 0)

                state.canSimple = true
                state.simpleBooking = {
                    id: booking.id,
                    amount: Math.abs(amount),
                    account: booking.parts[amount < 0 ? 1 : 0].account,
                    counterAccount: booking.parts[amount < 0 ? 0 : 1].account,
                }
            }
        },
        changeAmount: {
            prepare: amount => ({payload: 1 * amount}),
            reducer(state, {payload: amount}) {
                // React calls this randomly
                if (amount !== state.simpleBooking.amount) {
                    state.simpleBooking.amount = amount
                    calculateSimpleTax(state)
                }
            }
        },
        changeTax: {
            prepare: taxAccount => ({payload: {taxAccount}}),
            reducer(state, {payload: {taxAccount}}) {
                const percentage = state.taxAccounts[state.organization][taxAccount]?.percentage ?? .0

                state.simpleBooking.tax = percentage ? taxAccount : null;
                state.booking.tax = percentage

                calculateSimpleTax(state)
            }
        },
        changeAccount: {
            prepare: account => ({payload: account}),
            reducer(state, {payload: account}) {
                state.simpleBooking.account = account
                calculateSimpleTax(state)
            },
        },
        changeContraAccount: {
            prepare: account => ({payload: account}),
            reducer(state, {payload: account}) {
                state.simpleBooking.contraAccount = account
                calculateSimpleTax(state)
            },
        },

        // complex editor

        addPart(state) {
            partAdapter.addOne(state, {id: ++generatedId, account: {}})
            state.canRemovePart = true
            state.canSave = false
            state.canSimple = false
        },
        removePart: {
            prepare: id => ({payload: id}),
            reducer(state, {payload: id}) {
                partAdapter.removeOne(state, id)
                checkCanSave(state)
            }
        },
        changePartAccount: {
            prepare: (id, account) => ({payload: {id, account}}),
            reducer: (state, {payload: {id, account}}) => {
                partAdapter.updateOne(state, {
                    id, changes: {
                        account: {
                            ...account,
                            taxable: taxableAccountTypes.includes(account?.type)
                        }
                    }
                })

                if (state.canSimple) {
                    calculateSimpleTax(state)
                } else {
                    checkCanSave(state)
                }
            },
        },
        changePartDr: {
            prepare: (id, dr) => ({payload: {id, dr: 1 * dr}}),
            reducer: setPartAmount,
        },
        changePartCr: {
            prepare: (id, cr) => ({payload: {id, cr: 1 * cr}}),
            reducer: setPartAmount,
        },
        changePartPercentage: {
            prepare: (id, percentage) => ({payload: {id, percentage: 1 * percentage}}),
            reducer(state, {payload: {id, percentage}}) {
                if (partAdapter.getSelectors().selectById(state, id).percentage === percentage) {
                    return
                }

                partAdapter.updateOne(state, {id, changes: {percentage}})
                state.canSimple = false

                calculatePercentages(state, id)
            }
        },
        splitTax: {
            prepare: (id, account) => ({payload: {id, account}}),
            reducer(state, {payload: {id, account}}) {
                const {percentage} = state.taxAccounts[state.organization][account]
                const {dr, cr} = partAdapter.getSelectors().selectById(state, id)
                const tax = Math.round((dr ?? cr) * percentage)
                account = {id: account, modelId: "accounting/ledger-account", type: "TAX", taxable: false}

                if (dr) {
                    partAdapter.upsertMany(state, [
                        {id, dr: dr - tax},
                        {id: ++generatedId, account, dr: tax},
                    ])
                } else {
                    partAdapter.upsertMany(state, [
                        {id, cr: cr - tax},
                        {id: ++generatedId, account, cr: tax},
                    ])
                }

                state.canSimple = false

                checkCanSave(state)
            }
        }
    },
    extraReducers: builder => builder
        .addCase(setContext, (state, {payload: {organization}}) => {
            state.organization = organization
        })
        .addMatcher(accountingBaseDataLoaded, (state, {payload: {organizations}}) => {
            state.taxAccounts = Object.fromEntries(
                Object.entries(organizations).map(([id, {taxAccounts}]) => [
                    id,
                    taxAccounts
                ])
            )
        })
        .addMatcher(accountingBookingLoading, state => {
            state.open = true
            state.loading = true
        })
        .addMatcher(accountingBookingLoaded,
            /** @type {(state: EditorState, {payload: Booking}) => void} */
            function (state, {
                payload: {
                    id, parts, bookDate, receiptDate, receiptNumber,
                    subject, costObject, reference
                }
            }) {
                parts = parts.map(({id, account: {id: aId, modelId, type}, dr, cr}) => ({
                    id: id ?? ++generatedId,
                    account: {id: aId, modelId, type, taxable: taxableAccountTypes.includes(type)},
                    dr, cr
                }))

                state.open = true
                state.loading = false
                state.id = id
                state.tab = "complex"
                state.errors = undefined
                state.canSave = true
                state.canSimple = false
                state.simpleBooking = {}
                state.booking = {
                    id, bookDate, receiptDate, receiptNumber,
                    subject, costObject, reference,
                    stack: state.stack
                }
                partAdapter.setAll(state, parts)

                if (parts.length === 2 || parts.length === 3) {
                    let drAccount, crAccount, taxAccount, amount = 0

                    for (const {account, dr = 0, cr = 0} of parts) {
                        amount += dr

                        if (state.taxAccounts[state.organization][account.id]?.percentage) {
                            taxAccount = account.id
                            state.booking.tax = state.taxAccounts[state.organization][account.id].percentage
                        } else if (dr) {
                            drAccount = account
                        } else if (cr) {
                            crAccount = account
                        }
                    }

                    if (drAccount && crAccount && amount && (parts.length === 2 || taxAccount)) {
                        state.simpleBooking = {
                            amount,
                            account: drAccount,
                            contraAccount: crAccount,
                            tax: taxAccount,
                        }

                        calculateSimpleTax(state)
                        state.tab = "simple"
                        return
                    }
                }

                checkCanSave(state)
            }
        )
        .addMatcher(accountingBookingSaving, state => {
            state.errors = undefined
        })
        .addMatcher(accountingBookingSaved, state => {
            state.loading = false
            state.open = false
            flash.success("Erfolgreich gespeichert")
        })
        .addMatcher(accountingBookingSaveError, (state, {payload: {data, status}}) => {
            if (500 === status) {
                flash.error(data?.localizedMessage ?? data?.message ?? "Ein Fehler ist aufgetreten")
                state.errors = undefined
            } else {
                state.errors = data?.errors?.children ?? {}
                console.error(data?.errors?.children)
            }
        })
}))

const editor = editorSlice.reducer
const selectEditor = createSelector(state => state.accounting, ({editor}) => editor)
const selectOrganization = createSelector(selectEditor, ({organization}) => organization)
const selectTaxAccounts = createSelector(selectEditor, ({taxAccounts}) => taxAccounts)

export const {
    selectIds: selectPartIds,
    selectById: selectPartById,
    selectAll: selectAllParts,
} = partAdapter.getSelectors(selectEditor);

export default editor
export const {
    setTab,
    closeEditor,
    setBooking,
    setSimpleBooking,
    newBooking,
    changeBookDate,
    changeReceiptDate,
    changeReceiptNumber,
    changeSubject,
    changeCostObject,
    changeReference,
    changeAmount,
    changeTax,
    changeAccount,
    changeContraAccount,
    addPart,
    removePart,
    changePartAccount,
    changePartDr,
    changePartCr,
    changePartPercentage,
    splitTax,
} = editorSlice.actions

export const selectEditorDrawer = createSelector(selectEditor, ({open, loading}) => [open, loading])
export const selectBooking = createSelector(selectEditor, ({booking}) => booking)
export const selectSimpleBooking = createSelector(selectEditor, ({simpleBooking}) => simpleBooking)
export const selectErrors = createSelector(selectEditor, ({errors}) => errors)
export const selectTab = createSelector(selectEditor, ({tab}) => tab)

export const selectTaxChoices = createSelector(
    [selectOrganization, selectTaxAccounts],
    (organization, taxAccounts) => [
        {value: "", label: "0 %"},
        ...Object.entries(taxAccounts[organization]).map(
            ([value, {label}]) => ({value, label})
        )
    ]
)

export const selectCanRemovePart = createSelector(selectEditor, ({canRemovePart}) => canRemovePart)
export const selectCanSimple = createSelector(selectEditor, ({canSimple}) => canSimple)
export const selectCanSave = createSelector(selectEditor, ({canSave}) => canSave)
export const selectSums = createSelector(selectEditor, ({drSum, crSum}) => [drSum, crSum])
