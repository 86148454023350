export class ActionHandlerInterface {
    getActionType()
    {
        throw new Error('HandlerInterface::getActionType() must be implemented in subclass.');
    }

    getReturnContext(config, context)
    {
        throw new Error('HandlerInterface::getReturnContext() must be implemented in subclass.');
    }

    getAction(returnContext)
    {
        throw new Error('HandlerInterface::getAction() must be implemented in subclass.');
    }
}