import * as Sentry from '@sentry/browser';

export class SentryRequestInterceptor {
    requestError(error) {
        this.reportSentryError(error);

        throw error;
    }

    responseError(error) {
        this.reportSentryError(error);

        throw error;
    }

    async reportSentryError(error) {
        if (error instanceof Error) {
            Sentry.captureException(error);
        } else {
            if (this.shouldIgnoreError(error)) return;

            const body = await error.clone().text();

            Sentry.captureEvent({
                message: `${error.status} ${error.statusText} ${error.url}`,
                request: {
                    url: error.url,
                    status: error.status,
                    data: body
                },
                level: "error",
            });
        }
    }

    shouldIgnoreError(error) {
        const ignoreUrls = [
            "/order/commission-receiver/list",
            "/order/order-customer/list",
            "/base-user/user/list",
        ];

        if (error.status === 404 && ignoreUrls.find(url => error.url.indexOf(url) !== -1)) {
            return true;
        }

        return false;
    }
}
