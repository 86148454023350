import {inject} from "aurelia-framework";
import {DeleteActionHandler} from "./handlers/delete.action-handler";
import {OpenUrlActionHandler} from "./handlers/open-url.action-handler";
import {RequestActionHandler} from "./handlers/request.action-handler";
import {ViewActionHandler} from "./handlers/view.action-handler";
import {WorkflowActionHandler} from "./handlers/workflow.action-handler";
import {DuplicateActionHandler} from "./handlers/duplicate.action-handler";
import {ExecuteBulkOperationActionHandler} from "./handlers/execute-bulk-operation.action-handler";
import {MergeActionHandler} from "./handlers/merge.action-handler";
import {WizardActionHandler} from "./handlers/wizard.action-handler";
import {ArchiveActionHandler} from "./handlers/archive.action-handler";
import {EditorActionHandler} from "./handlers/editor.action-handler";
import {DocumentsPreviewActionHandler} from "./handlers/documents-preview.action-handler";

@inject(
    DeleteActionHandler,
    OpenUrlActionHandler,
    RequestActionHandler,
    ViewActionHandler,
    WorkflowActionHandler,
    DuplicateActionHandler,
    ExecuteBulkOperationActionHandler,
    MergeActionHandler,
    WizardActionHandler,
    ArchiveActionHandler,
    EditorActionHandler,
    DocumentsPreviewActionHandler
)
export class StandardActions
{
    actionHandlers = {};

    constructor(
        deleteActionHandler,
        openUrlActionHandler,
        requestActionHandler,
        viewActionHandler,
        workflowActionHandler,
        duplicateActionHandler,
        executeBulkOperationActionHandler,
        mergeActionHandler,
        wizardActionHandler,
        archiveActionHandler,
        editorActionHandler,
        documentsPreviewActionHandler
    ) {
        this.addActionHandler(deleteActionHandler);
        this.addActionHandler(openUrlActionHandler);
        this.addActionHandler(requestActionHandler);
        this.addActionHandler(viewActionHandler);
        this.addActionHandler(workflowActionHandler);
        this.addActionHandler(duplicateActionHandler);
        this.addActionHandler(executeBulkOperationActionHandler);
        this.addActionHandler(mergeActionHandler);
        this.addActionHandler(wizardActionHandler);
        this.addActionHandler(archiveActionHandler);
        this.addActionHandler(editorActionHandler);
        this.addActionHandler(documentsPreviewActionHandler);
    }

    addActionHandler(actionHandlerInstance)
    {
        if (this.actionHandlers[actionHandlerInstance.getActionType()]) {
            throw new Error(`Action handler for action of type "${actionHandlerInstance.getActionType()}" was defined several times.`);
        }

        this.actionHandlers[actionHandlerInstance.getActionType()] = actionHandlerInstance;
    }

    getAction(config, context)
    {
        if (!config) {
            return null;
        }

        if (!this.actionHandlers[config.type]) {
            throw new Error(`Action handler for action of type "${config.type}" was never defined.`);
        }

        let actionHandler = this.actionHandlers[config.type];

        let returnContext = actionHandler.getReturnContext(config, context);
        let action = actionHandler.getAction(returnContext);

        return {
            action: action,
            context: returnContext
        };
    }
}
