import {customElement, bindable} from "aurelia-framework";

@customElement('sio-button-with-icon')
export class ButtonWithIcon
{
    @bindable label;
    @bindable name;
    @bindable click;
    @bindable buttonClass = "btn btn-default btn-sm";
    @bindable icon = null;
    @bindable showLabel = true;
    @bindable loading = false;
}
