import {LogManager, inject} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {Preview} from "../../dialog/preview";

const logger = LogManager.getLogger('action-handler');

@inject(
    DialogService
)
export class RequestCallbacks
{
    constructor(dialog)
    {
        this.dialog = dialog;
        logger.debug('Dialog initialized', this.dialog);
    }

    isValidCallback(callback)
    {
        switch (callback) {
            case 'download':
            case 'debug':
            case 'preview':
                return true;
        }

        throw new Error(
            'Unknown callback `' + callback + '` specified for sio-action with type `request`'
        );
    }

    executeCallback(callback, response)
    {
        switch (callback) {
            case 'download':
                return this.download(response);
            case 'debug':
                return this.debug(response);
            case 'preview':
                return this.preview(response);
            default:
                if (callback) {
                    return callback(response);
                }
        }
    }

    download(response)
    {
        logger.debug('DOWNLOAD RESPONSE', response);

        if (null != response.url) {
            this._downloadRedirect(response.url);
            return;
        }

        this._downloadBase64(response.filename, response.content, response.mimetype, response.base64);
    }

    debug(response)
    {
        logger.debug('RECEIVED RESPONSE', response);
    }

    preview(response)
    {
        logger.debug('PREVIEW RESPONSE', response, this.dialog);

        this.dialog.open({viewModel: Preview, model: { title: 'sio.preview', message: response }}).whenClosed();
    }

    /*private*/ _downloadRedirect(url)
    {
        let element = document.createElement('a');
        element.setAttribute('href', url);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    /*private*/ _downloadBase64(filename, content, mimetype = 'application/octet-stream', base64 = true)
    {
        let base64prefix = base64 ? 'base64,' : '';

        let element = document.createElement('a');
        element.setAttribute('href', 'data:' + mimetype + ';charset=utf-8;' + base64prefix + encodeURIComponent(content));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
}
