import {bindable, customElement, inject} from 'aurelia-framework';

@customElement('balance-sheet-table')
@inject(Element)
export class MyElement {
    @bindable title;
    @bindable data;
    @bindable items;

    constructor(element) {
        this.element = element;
    }

    open(btnIndex, mainId) {
        this.element.dispatchEvent(new CustomEvent('open', {
            detail: {btnIndex, mainId},
            bubbles: true,
        }));
    }
}
