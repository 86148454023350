import {inject, LogManager} from 'aurelia-framework';
import {Client} from './client';
import * as _ from 'lodash';

@inject(Client)
export class ModuleConfigClient
{

    constructor(client) {
        this.client = client;
    }

    getModule(moduleId)
    {
        return this.client.get('module/' + moduleId, 60);
    }

    _getSubProperty(combinedId, subProperty)
    {
        let parts = combinedId.split('/');

        if (parts.length < 2) {
            return Promise.reject(new Error('Invalid id given ' + combinedId));
        }

        return this.client.get('module/' + parts[0], 60).then(
            (targetModule) =>
            {
                let element = _.find(targetModule[subProperty], (element) => element.id === parts[1]);

                if (!element) {
                    throw new Error(`Couldn't resolve config for ${subProperty} "${combinedId}"`);
                }

                return element;
            }
        );
    }

    getTabs(combinedId)
    {
        return this.client.get( combinedId + '/tabs', 60);
    }

    getModel(combinedId)
    {
        return this._getSubProperty(combinedId, 'models');
    }

    getView(combinedId)
    {
        return this._getSubProperty(combinedId, 'views');
    }

    getWorkflow(combinedId)
    {
        return this._getSubProperty(combinedId, 'workflows');
    }
}
