import {api, onQueryStarted} from "../../store/api"
import {Container} from "aurelia-dependency-injection";
import {FlashService} from "../../flash/flash-service";
import moment from "moment";

/** @type FlashService */
const flash = Container.instance.get(FlashService)

/**
 * @typedef {Object} AccountingBooking
 * @property {string} booking
 * @property {string} state
 * @property {Money} dr
 * @property {Money} cr
 */

/**
 * Invalidates booking tags after 250ms to let ElasticSearch update first.
 */
async function delayedInvalidate(queryArg, {dispatch, queryFulfilled}) {
    const ids = "string" === typeof queryArg ? [queryArg] :
        Array.isArray(queryArg) ? queryArg :
            queryArg?.bookings ?? undefined
    const tags = ids?.length ?
        ids.map(id => ({type: "accounting/booking", id})) :
        ["accounting/booking"];

    try {
        const {data} = await queryFulfilled

        if (Array.isArray(data) && "string" === typeof data[0]?.message) {
            flash.flash(data[0]._failed ? "error" : "success", data[0].message)
        }

        // wait 250 ms to refetch ledger account display
        if (!data?.[0]?._failed) {
            setTimeout(
                () => {
                    dispatch(api.util.invalidateTags(tags))
                },
                250
            )
        }

        return data
    } catch (error) {
        console.error(error)

        if (Array.isArray(error?.error?.data?.errors?.errors)) {
            flash.error(error.error.data.errors.errors.join(''))
        } else {
            flash.error(error.error ?? error.localizedMessage ?? error.message ?? error)
        }
    }
}

const accountingApi = api.injectEndpoints({
    endpoints: build => ({
        accountingBaseData: build.query({
            query: () => "accounting/base-data",
        }),

        accountingEntries: build.query({
            query: ({context, account, stack, organization, costObject, reference, ...additional}) => {
                const params = new URLSearchParams()
                let url = "accounting/"

                switch (context) {
                    case "account":
                        url += "ledger-account/" + account
                        break

                    case "stack":
                        url += "stack/" + stack
                        break

                    default:
                        url += "ledger-account/organization/" + organization
                }

                if (costObject?.id) {
                    params.set("costObject[id]", costObject.id)
                    params.set("costObject[modelId]", costObject.modelId)
                }

                if (reference?.id) {
                    params.set("reference[id]", reference.id)
                    params.set("reference[modelId]", reference.modelId)
                }

                Object.entries(additional).filter(e => e[1]).forEach(([key, value]) => params.set(key, value))

                url += "/entries?" + params.toString()

                return {url}
            },
            providesTags: result => [
                "accounting/booking",
                ...(result?.entries ?? []).map(({booking}) => ({type: "accounting/booking", id: booking}))
            ],
            /**
             * @param {AccountingBooking[]} entries
             * @param meta
             * @param {string} context
             * @param {string} account
             */
            transformResponse(entries, meta, {context, account}) {
                const hasCleared = "journal" !== context && entries.some(({cleared}) => cleared?.length)

                const clearable = "account" !== context ? [] : entries
                    .filter(({
                                 cleared = [],
                                 state
                             }) => !cleared.some(({account: {id}}) => id === account) && "booked" === state)
                    .map(({booking}) => booking);

                return {
                    entries,
                    amounts: entries.map(({booking, cr, dr}) => [booking, cr?.amount ?? 0, dr?.amount ?? 0]),
                    currency: entries.reduce(
                        (currency, {cr, dr}) => currency ?? cr?.currency ?? dr?.currency,
                        undefined
                    ),
                    clearable: clearable,
                    states: Array.from(new Set(entries.map(({state}) => state)).values()),
                    hasCleared,
                    hasUncleared: hasCleared && entries.some(({cleared}) => !cleared?.length)
                }
            }
        }),

        accountingBookingAction: build.mutation({
            query: ({id, action}) => ({
                url: `accounting/booking/${id}/${action}`,
                method: "PATCH"
            }),
            onQueryStarted(args, definition) {
                return onQueryStarted("Aktion erfolgreich." + ("book" === args?.action ? " Das Festschreiben wird verzögert durchgeführt." : ""))(args, definition)
            },
            invalidatesTags: (result, error, {id}) => [{type: "accounting/booking", id}]
        }),

        loadAccountingBooking: build.query({
            query: id => `accounting/booking/${id}?embeds[]=parts.account`,
            providesTags: ({id}) => ([{type: "accounting/booking", id}]),
            // ensure that only editable properties are used:
            transformResponse: booking => !booking ? booking : {
                id: booking.id,
                bookDate: booking.bookDate,
                receiptDate: booking.receiptDate,
                receiptNumber: booking.receiptNumber,
                subject: booking.subject,
                costObject: booking.costObject,
                reference: booking.reference,
                organization: booking.organization,
                stack: booking.stack,
                parts: (booking.parts ?? []).map(({account, cr, dr}) => ({account, cr, dr})),
            }
        }),

        saveAccountingBooking: build.mutation({
            query: (
                {
                    id, parts, bookDate, receiptDate, receiptNumber, subject,
                    costObject, reference, stack, organization
                }
            ) => ({
                url: `accounting/booking${id ? "/" + id : ""}`,
                method: id ? "PUT" : "POST",
                body: {
                    parts: parts.map(({account: {id, modelId}, cr, dr}) => ({account: {id, modelId}, cr, dr})),
                    bookDate: moment(bookDate).format('YYYY-MM-DD') + 'T00:00:00Z',
                    receiptDate: moment(receiptDate).format('YYYY-MM-DD') + 'T00:00:00Z',
                    receiptNumber,
                    subject,
                    costObject,
                    reference,
                    organization,
                    stack
                }
            }),
            onQueryStarted: ({id, book}, {dispatch, queryFulfilled}) => {
                return delayedInvalidate(id, {
                    dispatch,
                    queryFulfilled: (async function() {
                        const result = await queryFulfilled

                        // if necessary, dispatch a "book" mutation first before returning the original save promise
                        if (book && "string" === typeof result?.data) {
                            await dispatch(accountingApi.endpoints.accountingBookingAction.initiate({
                                id: result.data,
                                action: "book"
                            }))
                        }

                        return result
                    })()
                })
            }
        }),
        deleteAccountingBooking: build.mutation({
            query: id => ({
                url: `accounting/booking/${id}`,
                method: "DELETE"
            }),
            onQueryStarted: delayedInvalidate
        }),

        clearBookings: build.mutation({
            query: ({bookings: ids, account: id}) => ({
                url: "accounting/bookings-cleared",
                method: "POST",
                body: {
                    bookings: ids.map(id => ({id, modelId: "accounting/booking"})),
                    account: {id, modelId: "accounting/ledger-account"}
                }
            }),
            onQueryStarted: delayedInvalidate
        }),

        deleteBookingsCleared: build.mutation({
            query: ({id}) => ({
                url: `accounting/bookings-cleared/${id}`,
                method: "DELETE"
            }),
            onQueryStarted: delayedInvalidate
        }),

        vatStatementFieldHistory: build.query({
            query: ({statement, field}) => `accounting/vat-statement/${statement}/field-history/${field}`
        })
    })
});

export const {
    useAccountingBaseDataQuery,
    useAccountingEntriesQuery,
    useAccountingBookingActionMutation,
    useSaveAccountingBookingMutation,
    useDeleteAccountingBookingMutation,
    useClearBookingsMutation,
    useDeleteBookingsClearedMutation,
    useVatStatementFieldHistoryQuery,
    endpoints: {
        accountingBaseData: {
            matchFulfilled: accountingBaseDataLoaded
        },
        accountingEntries: {
            matchFulfilled: accountingEntriesLoaded
        },
        loadAccountingBooking: {
            initiate: loadAccountingBooking,
            matchPending: accountingBookingLoading,
            matchFulfilled: accountingBookingLoaded
        },
        saveAccountingBooking: {
            matchPending: accountingBookingSaving,
            matchFulfilled: accountingBookingSaved,
            matchRejected: accountingBookingSaveError,
        },
        clearBookings: {
            matchFulfilled: clearBookingsFulfilled,
        }
    }
} = accountingApi
