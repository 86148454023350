import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../api/client";
import * as _ from "lodash";
import {BindingSignaler} from "aurelia-templating-resources";
import {EventAggregator} from "aurelia-event-aggregator";

@customElement('address-selector')
@inject(Client, BindingSignaler, EventAggregator)
export class AddressSelector {
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    selects = [];

    constructor(client, signaler, ea) {
        this.client = client;
        this.signaler = signaler;
        this.ea = ea;
    }

    async configChanged(newValue, oldValue) {

        if (this.formValueChangedSubscription) {
            this.formValueChangedSubscription.dispose();
        }

        this.formValueChangedSubscription = this.ea.subscribe(
            'sio_form_value_changed', this.onFormValueChangedCallback.bind(this)
        );

        await this._reloadSelections();
    }

    async _reloadSelections()
    {
        console.log('reload selections');

        let value = this.config.formService.getValue();

        let selects = [];

        _.each(this.config.selects, (property) => {

            let selection = _.get(value, property);

            if (!selection) {
                return;
            } else if (_.isArray(selection)) {
                selects = selects.concat(selection);
            } else {
                selects.push(selection);
            }
        });

        this.selects = selects;

        let promises = [];

        _.each(this.selects, (select) => {

            if (!select) {
                return;
            }

            let modelId = select.modelId;
            let id = select.id;

            promises.push(this.client.get(modelId + '/' + id).then(item => {

                let result = [];

                let addresses = _.get(item, 'addresses');

                if (addresses) {
                    if (_.isArray(addresses)) {
                        result = addresses;
                    } else {
                        result.push(addresses);
                    }
                }

                let address = _.get(item, 'address');

                if (address) {
                    if (_.isArray(address)) {
                        result = result.concat(address);
                    } else {
                        result.push(address);
                    }
                }

                return result;
            }));

        });

        this.selections = [];

        await Promise.all(promises).then(promiseResults => {

            this.selections = promiseResults;

            console.log('addresses', promiseResults);

            this._recheckAddresses();

        });
    }

    async valueChanged() {
        this._recheckAddresses();
    }

    /**
     * This callback is executed when value of some form in application is updated.
     *
     * @param event {Object} Object that contains two fields:
     *                       - form : object of Form class that changed value
     *                       - field : object of FormField class that changed value and belongs to specified form
     */
    onFormValueChangedCallback(event)
    {
        // Verification that choice input belongs to form that fired event.

        if (event.form.formService !== this.config.formService) {
            return;
        }

        if (event.field.property === this.config.property) {
            return;
        }

        this._reloadSelections();
    }

    selectAddress(address) {

        let clone = _.omit(_.cloneDeep(address), ['modelId', 'selected', 'id', 'addressIdentifier']);

        this.value = clone;

        //Explicitely set all values to null, otherwise form does not clear properly
        _.each([
            'lastName',
            'firstName',
            'company',
            'salutation',
            'title',
            'addressLine1',
            'addressLine2',
            'zip',
            'city',
            'region',
            'country',
        ], property => {

            if (!this.value[property]) {
                this.value[property] = null;
            }

        });

        console.log('address selected', this.value);
    }

    _recheckAddresses() {
        if (this.value) {
            _.each(this.selections, (addresses) => {

                _.each(addresses, address => {

                    address.selected = false;

                    if (this._compareAddress(address, this.value)) {
                        address.selected = true;
                    }

                });

            });
        }

        console.log('address', this.selections, this.value);
    }

    _compareAddress(a, b) {
        return a.salutation == b.salutation &&
            a.title == b.title &&
            a.firstName == b.firstName &&
            a.lastName == b.lastName &&
            a.company == b.company &&
            a.addressLine1 == b.addressLine1 &&
            a.addressLine2 == b.addressLine2 &&
            a.zip == b.zip &&
            a.city == b.city &&
            a.region == b.region &&
            a.country == b.country;
    }
}
