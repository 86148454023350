import {inject} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {Client} from "../../api/client";
import {FlashService} from "../../flash/flash-service";
import {DialogService} from "aurelia-dialog";
import {Confirm} from "../../dialog/confirm";
import {EventAggregator} from "aurelia-event-aggregator";

@inject(
    Client,
    FlashService,
    DialogService,
    EventAggregator
)
export class ArchiveActionHandler extends ActionHandlerInterface {

    constructor(client, flash, dialog, ea) {
        super();

        this.client = client;
        this.flash = flash;
        this.dialog = dialog;
        this.ea = ea;
    }

    getActionType() {
        return 'archive';
    }

    getReturnContext(config, context) {
        return Object.assign(
            {
                message: 'archive.' + config.name + '.confirm',
                okMessage: 'action.' + config.name,
                cancelMessage: 'sio.cancel',
                modelId: config.modelId,
                name: config.name,
                bulk: true
            },
            context
        );
    }

    getAction(returnContext) {
        return () => {
            return this.dialog.open({viewModel: Confirm, model: returnContext}).whenClosed(response => {
                if (response.wasCancelled) {
                    return;
                }

                return this.client
                    .patch(
                        returnContext.modelId + '/' + _.castArray(returnContext.id).join(',') +
                        '/' + returnContext.name
                    )
                    .then(data => {
                        this.ea.publish(
                            'sio_form_post_submit',
                            {config: {modelId: returnContext.modelId}}
                        );

                        this.flash.response(data, 'success', 'archive.' + returnContext.name + '.success');

                    }, error => {

                        let message = 'archive.' + returnContext.name + '.error';

                        if (error.hasOwnProperty('data') && error.data.hasOwnProperty('message')) {
                            message = error.data.message;
                        }

                        console.error(error);
                        this.flash.response(error, 'error', message);
                    });
            });
        };
    }
}
