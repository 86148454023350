import {Container, inject, LogManager, NewInstance} from "aurelia-framework";
import {HttpClient, json} from "aurelia-fetch-client";
import {AureliaConfiguration} from 'aurelia-configuration';
import {AuthInterceptor} from '../auth/auth-interceptor';
import {LogRequestInterceptor} from './log-request-interceptor';
import {EventAggregator} from 'aurelia-event-aggregator';
import {SentryRequestInterceptor} from './sentry-request-interceptor';
import {FlashService} from "../flash/flash-service";

const logger = LogManager.getLogger('Client');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(NewInstance.of(HttpClient), AureliaConfiguration, AuthInterceptor, LogRequestInterceptor, SentryRequestInterceptor, EventAggregator, FlashService)
export class Client {

    client = null;
    cache = {};

    constructor(httpClient, appConfig, interceptor, logInterceptor, sentryInterceptor, ea, flash) {
        this.client = httpClient;
        this.ea = ea;
        this.flash = flash;

        this.client.configure(config => config
            .useStandardConfiguration()
            .withBaseUrl(appConfig.get('apiUrl'))
            .withInterceptor(interceptor)
            //Todo add only if develop logging is on
            .withInterceptor(sentryInterceptor)
            .withInterceptor(logInterceptor)
        );

        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {
            if (response?.config?.modelId) {
                this.removeCache(response.config.modelId);
                this.removeCache('model/choice/' + response.config.modelId);
            }
        });

        this.loginSubscription = this.ea.subscribe('sio_login_change', () => this.cache = {});
    }

    destructor() {
        this.subscription.dispose();
        this.loginSubscription.dispose();
    }

    static getInstance() {
        if (!Client._instance) {
            Client._instance = Container.instance.get(Client);
        }

        return Client._instance;
    }

    removeCache(urlStartsWith) {
        for (const key of Object.keys(this.cache)) {
            if (key.startsWith(urlStartsWith)) {
                delete this.cache[key];
            }
        }
    }

    async request(method, url, body = null, expiry = null, _options = null) {
        method = method.toUpperCase();

        if (method === 'GET') {
            return this.get(url, expiry).then((data) => {
                return {data: data};
            });
        }

        const options = Object.assign({method: method}, _options);

        if (body) {
            options.body = body instanceof FormData ? body : json(body);
        }

        //Needed for fin api
        options.referrerPolicy = 'unsafe-url';

        return this.client.fetch(url, options).then(response => {
            if (response.headers.has('X-Core2-Alerts')) {
                (JSON.parse(response.headers.get('X-Core2-Alerts')) ?? []).forEach(alert => this.flash.warning(alert));
            }
            return this.extractJsonFromResponse(response);
        }, response => {
            if (response instanceof DOMException && "AbortError" === response.name) {
                throw response;
            }
            return this.extractJsonFromResponse(response).then(response => {
                throw response;
            });
        });
    }

    async get(url, expiry, _options) {

        if (expiry) {
            if (this.cache[url] != null) {
                if (this.cache[url][1] < new Date()) {
                    logger.debug('Expired cache', url, this.cache[url]);

                    delete this.cache[url];
                } else {
                    logger.debug('Fetch from cache', url, this.cache[url]);

                    return this.cache[url][0];
                }
            }
        }

        logger.debug('Fetch from URL', url);

        let promise = this.client.fetch(url, _options)
            .then(
                response => this.extractJsonFromResponse(response).then(response => response.data),
                response => {
                    if (response instanceof DOMException && "AbortError" === response.name) {
                        throw response;
                    }
                    return this.extractJsonFromResponse(response).then(response => {
                        throw response;
                    });
                }
            );

        if (expiry) {

            var t = new Date();
            t = new Date(t.getTime() + (expiry * 1000));

            this.cache[url] = [promise, t];
        }

        return promise;
    }

    async post(url, body, _options) {
        return this.request('POST', url, body, null, _options);
    }

    async put(url, body, _options) {
        return this.request('PUT', url, body, null, _options);
    }

    async patch(url, body, _options) {
        return this.request('PATCH', url, body, null, _options);
    }

    async remove(url, body = null) {
        return this.request('DELETE', url, body);
    }

    async head(url, body) {
        return this.request('HEAD', url, body);
    }

    extractJsonFromResponse(response) {
        // if (!response.ok) {
        //     return Promise.reject({data: null});
        // }

        return response.text().then(data => {
            try {
                response.data = data.length != 0 ? JSON.parse(data) : null;
            } catch (e) {
                response.data = null;
            }

            return response;
        });
    }

    getClient (){
        return this.client;
    }
}

export default Client;
