import {AuthorizeStep} from './auth/auth-authorize-step';
import {inject, PLATFORM} from 'aurelia-framework';
import {RouterHistory} from "./router/history";
import {UserClient} from "./api/user-client";
import * as Sentry from '@sentry/browser';
import {AureliaConfiguration} from 'aurelia-configuration';

@inject(UserClient, AureliaConfiguration)
export class App {

    constructor(userClient, appConfig) {
        this.userClient = userClient;
        this.appConfig = appConfig;

        if (this.appConfig.get('staging') != true) {
            Sentry.init({
                dsn: 'https://298fae30560748c19cab6fdce35a0109@sentry.io/1470669',
                environment: this.appConfig.get('staging') ? 'dev' : 'prod',
                maxValueLength: 5000, // default = 250, @see https://docs.sentry.io/platforms/javascript/#truncating-strings-on-the-event
            });
        }
    }

    configureRouter(config, router) {
        config.title = 'SIO';
        config.options.pushState = true;
        config.options.root = '/';
        config.map([
            {
                route: '',
                name: 'welcome',
                moduleId: PLATFORM.moduleName('./welcome', 'welcome'),
                title: 'SIO Dashboard',
            },
            {
                route: 'view/:moduleId/:viewId',
                name: 'view',
                moduleId: PLATFORM.moduleName('./view/view'),
                title: ''
            },
            {
                route: 'login',
                name: 'login',
                redirect: ''
            },
            {
                route: 'login/redirect/:token/:refresh/:expires',
                name: 'loginRedirect',
                moduleId: PLATFORM.moduleName('./login/loginRedirect', 'login'),
                settings: {hideNav: true, noAuth: true},
            },
            {
                route: 'login/activate/:token/:username',
                name: 'activate',
                moduleId: PLATFORM.moduleName('./login/activate', 'login'),
                settings: {hideNav: true, noAuth: true},
                data: {
                    reset: false
                }
            },
            {
                route: 'login/reset-password/:token/:username',
                name: 'activate',
                moduleId: PLATFORM.moduleName('./login/activate', 'login'),
                settings: {hideNav: true, noAuth: true},
                data: {
                    reset: true
                }
            },
            {
                route: 'login/request-reset',
                name: 'reset',
                moduleId: PLATFORM.moduleName('./login/reset', 'login'),
                settings: {hideNav: true, noAuth: true}
            },
            {
                route: 'merge/:moduleId/:modelId/:id',
                name: 'merge',
                moduleId: PLATFORM.moduleName('./merge/merge-tool', 'merge'),
                title: 'Daten zusammenführen'
            },
            {
                route: 'post.php',
                name: 'gutenberg-editor-error',
                moduleId: PLATFORM.moduleName('./editor/error-redirect')
            }
        ]);

        config.addPipelineStep('authorize', AuthorizeStep);
        config.addPostRenderStep(new RouterHistory);

        config.mapUnknownRoutes(PLATFORM.moduleName('./router/not-found.html'));

        this.router = router;
    }

    async activate()
    {
        await this.userClient.load();
    }
}
