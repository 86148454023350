import {bindable} from "aurelia-framework";
import * as _ from "lodash";
import {inject} from "aurelia-dependency-injection";
import {FormServiceFactory} from "../form/service/form-service-factory";
import {BindingSignaler} from "aurelia-templating-resources";
import {EventAggregator} from "aurelia-event-aggregator";

@inject(FormServiceFactory, BindingSignaler, EventAggregator)
export class AddressMap {

    @bindable({defaultBindingMode: 2}) value;
    @bindable name;
    @bindable apiKey;
    @bindable language;
    @bindable uuid;
    markers;

    updated = false;


    config = {
        template: '',
        fields: [
            {
                type: 'number',
                property: 'lng',
                label: 'maps.coordinates.lng',
                required: false
            },
            {
                type: 'number',
                property: 'lat',
                label: 'maps.coordinates.lat',
                required: false
            }
        ]
    };


    constructor(formServiceFactory, signaler, eventAggregator) {
        this.formServiceFactory = formServiceFactory;
        this.signaler = signaler;
        this.ea = eventAggregator;
    }

    bind() {
        this._config = _.cloneDeep(this.config);
        if (this.formService) {
            this.formService.changeCallback = null;
        }

        this.formService = this.formServiceFactory.getFormService(this._config, _.cloneDeep(this.value));
        this.formService.changeCallback = this.formValueChanged.bind(this);

        this.updateMarker();
    }


    formValueChanged() {
        this.value = this.formService.getValue();

        if (this.value && this.value.lat && this.value.lng) {
            this.updated = true;
        }

    }

    valueChanged(newValue) {

        console.log('NEW VALUE', newValue);

        this.formService.setValue(this.value);
        this.updateMarker();
    }

    updateMarker() {
        this.markers = [];

        let position;

        if (this.value && this.value.lat && this.value.lng) {
            position = {
                lat: parseFloat(this.value.lat),
                lng: parseFloat(this.value.lng)
            }
        }

        this.markers.push({
            title: this.name,
            name: this.name,
            draggable: true,
            onDragend: this.markerOnMoveCallback.bind(this),
            position: position
        });
    }

    markerOnMoveCallback(mapProps, map) {
        this.value = {
            lat: map.position.lat().toString(),
            lng: map.position.lng().toString(),
            update: false,
        }

        if (this.value && this.value.lat && this.value.lng) {
            this.updated = true;
        }
    }

    publishGeocodingEvent() {
        this.ea.publish('sio_address.geocode.update.address', {
            type: 'coordinates',
            value: this.value,
            uuid: this.uuid,
        })
        this.updated = false;
    }

    doNotUpdate() {
        this.updated = false;
    }
}
