import {inject} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {Router} from "aurelia-router";

@inject(Router)
export class MergeActionHandler extends ActionHandlerInterface {
    constructor(router) {
        super();
        this.router = router;
    }

    getActionType() {
        return 'merge';
    }

    // noinspection JSUnusedLocalSymbols
    getReturnContext(config, context) {
        return {
            bulk: true,
            context: context,
            noDuplicatesInDependers: config.noDuplicatesInDependers,
            databaseAccessStrategy: config.databaseAccessStrategy,
        };
    }

    getAction(returnContext) {
        return () => {
            const [moduleId, modelId] = returnContext.context.modelId.split('/');

            return this.router.navigateToRoute('merge', {
                moduleId: moduleId,
                modelId: modelId,
                id: returnContext.context.id.join(','),
                noDuplicatesInDependers: returnContext.noDuplicatesInDependers,
                databaseAccessStrategy: returnContext.databaseAccessStrategy,
            });
        };
    }
}
