import React from "react";
import ReactDOM from "react-dom";
import { bindable, inject, noView } from "aurelia-framework";
import * as _ from "lodash";
import { I18N } from "aurelia-i18n";
import { Client } from "../api/client";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";

import { GoogleApiWrapper } from "google-maps-react";

import "./address-places-autocomplete-input.less";
import { FlashService } from "../flash/flash-service";
import { EventAggregator } from "aurelia-event-aggregator";

@noView()
@inject(Element, Client, FlashService, I18N, EventAggregator)
export class AddressPlacesAutocompleteInput {
    @bindable({ defaultBindingMode: 2 }) value;
    @bindable person;
    @bindable singleLine;
    @bindable apiKey;
    @bindable language;
    @bindable uuid;

    reactComponent = {};
    client;

    address;
    selectedAddress;

    subscribers = [];

    constructor(element, client, flashService, i18n, eventAggregator) {
        this.element = element;
        this.client = client;
        this.flashService = flashService;
        this.i18n = i18n;
        this.ea = eventAggregator;
        window.gm_authFailure = () => {
            flashService.error('maps-api-keys.invalid');
        }
    }

    SIOGoogleAutocomplete = GoogleApiWrapper((props) => ({
        apiKey: props.apiKey,
        language: props.language,
    }))(LocationSearchInput);

    render() {
        if (!this.apiKey) {
            this.flashService.error('maps-api-keys.missing');
            return;
        }

        this.reactComponent = ReactDOM.render(
            <this.SIOGoogleAutocomplete
                apiKey={this.apiKey}
                language={this.language}
                onHandleSelect={this.updateAddressCoordinates.bind(this)}
                onHandleError={this.handleError.bind(this)}
                placeholder={this.i18n.tr("maps.address.search")}
                type={this.singleLine ? "search" : "text"}
                value={this.singleLine ? this.value : null}
            />,
            this.element
        );
    }

    handleError(error) {
        switch (error) {
            case "ZERO_RESULTS":
                this.flashService.error("Keine Ergebnisse");
                break;

            default:
                this.flashService.error("Keine Ergebnisse");
        }
    }

    async updateAddressCoordinates(selected_address, updateAddress = true) {
        try {
            if (selected_address === null) {
                this.value.formattedAddress = null;
                return
            }
            this.selectedAddress = selected_address;
            let place = await this.retrievePlace(selected_address);
            this.updatePlaceId(place);
            await this.updateCoordinates(place);
            await this.updatePersonDetails(place);
            if(updateAddress){
                let latlng = await getLatLng(place);
                await this.updateAddressOnly(latlng.lat.toString(), latlng.lng.toString());
            }else{
                this.value = this.address;
            }
        } catch (err) {
            this.handleError(err);
        }
    }

    async updateAddressOnly(lat, lng) {
        try {
            let place = await this.retrievePlace(lat + ", " + lng);

            this.updatePlaceId(place);

            this.address.coordinates = {
                lat: lat,
                lng: lng,
                update: false,
            };

            await this.updatePersonDetails(place);

            this.value = this.address;
        } catch (err) {
            this.handleError(err);
        }
    }

    retrievePlace(selected_address) {
        return new Promise(async (resolve, reject) => {
            let result = await geocodeByAddress(selected_address);
            const map = new google.maps.Map(document.getElementById("map"));
            const request = {
                placeId: result[0].place_id,
                fields: ["adr_address"],
            };

            const service = new google.maps.places.PlacesService(map);

            service.getDetails(request, (place, status) => {
                this.address = {};
                this.parseAddress(
                    place.adr_address,
                    result[0].address_components
                ).then((response) => {
                    let place = result[0];
                    resolve(place);
                });
            });
        });
    }

    parseAddress = (adrAddress, addressComponents) => {
        return new Promise((resolve, reject) => {
            const addressDetail = {
                formattedAddress: this.selectedAddress
            };
            const fields = [
                "addressLine1",
                "addressLine2",
                "zip",
                "city",
                "region",
                "country",
            ];
            fields.forEach((field) => (addressDetail[field] = null));
            const address = adrAddress;
            const div = document.createElement("div");
            div.innerHTML = address;
            for (let child of div.children) {
                const [className] = [...child.classList];
                switch (className) {
                    case "street-address":
                        addressDetail.addressLine1 = child.innerHTML;
                        break;
                    case "locality":
                        addressDetail.city = child.innerHTML;
                        break;
                    case "postal-code":
                        addressDetail.zip = child.innerHTML;
                        break;
                    default:
                        break;
                }
            }

            for (let component of addressComponents) {
                switch (true) {
                    case component.types.includes(
                        "administrative_area_level_1"
                    ) && component.types.includes("political"): {
                        addressDetail.region = component.long_name;
                        break;
                    }

                    case component.types.includes(
                        "administrative_area_level_1"
                    ) &&
                        component.types.includes(
                            "administrative_area_level_2"
                        ): {
                        if (addressDetail.city === null) {
                            addressDetail.region = component.long_name;
                        }
                        break;
                    }

                    case component.types.includes("country") &&
                        component.types.includes("political"):
                        addressDetail.country = component.short_name;
                        break;
                }
            }
            this.address = addressDetail;
            resolve(true);
        });
    };

    updatePlaceId(place) {
        this.address.placeId = place.place_id;
    }

    async updateCoordinates(place) {
        let latlng = await getLatLng(place);
        this.address.coordinates = {
            lat: latlng.lat.toString(),
            lng: latlng.lng.toString(),
            update: false,
        };
    }

    async updatePersonDetails(place) {
        if (!this.person || !place.types.includes("point_of_interest")) {
            return;
        }

        let place_details = await placeDetails(place.place_id);

        _.each(
            ["lastName", "firstName", "company", "salutation", "title"],
            (property) => {
                this.value[property] = null;
            }
        );

        this.address.company = place_details.name;
    }

    async attached() {
        await this.loadApiKey();
        this.subscribers.push(
            this.ea.subscribe(
                "sio_address.geocode.update.address",
                this.updateGeocodingAddress.bind(this)
            )
        );
        this.subscribers.push(
            this.ea.subscribe(
                "sio_address.geocode.update.coordinates",
                this.updateGeocodingCoordinates.bind(this)
            )
        );
        this.render();
    }

    async loadApiKey() {
        if (this.apiKey) {
            return;
        }

        let settings = await this.client.get("maps/google/apikey", true);
        if (typeof settings !== "undefined") {
            this.apiKey = settings.apiKey ?? "";
            this.language = settings.locale ?? "en";
        }
    }

    async updateGeocodingAddress(evt) {
        if (!evt || evt.type != "coordinates" || !evt.value) {
            return;
        }

        if (evt.uuid !== this.uuid) {
            return;
        }

        await this.updateAddressOnly(evt.value.lat, evt.value.lng);
    }

    async updateGeocodingCoordinates(evt) {
        if (!evt || evt.type != "address" || !evt.value) {
            return;
        }

        if (evt.uuid !== this.uuid) {
            return;
        }

        let value = _.cloneDeep(evt.value);
        value = _.filter(value, (value, key) => {
            return (
                [
                    "addressLine1",
                    "addressLine2",
                    "country",
                    "city",
                    "zip",
                ].includes(key) &&
                value != null &&
                value.length > 0
            );
        });

        if (!Array.isArray(value) || !(value.length > 2)) {
            return;
        }

        await this.updateAddressCoordinates(value.join(", "), evt.type == "address" ? false : true );
    }

    detached() {
        this.subscribers.forEach((subscriber) => {
            subscriber.dispose();
        });
    }
}

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: props.value?.formattedAddress ?? '',
            searchOptions: {
                sessionToken: null,
            },
            timeout: null,
        };
    }

    handleChange = (address) => {
        if (address === '' || address == null) {
            this.props.onHandleSelect(null);
        }
        this.setState({ address });
        this.createSessionToken();
    };

    handleSelect = (address) => {
        this.setState({ address });
        this.props.onHandleSelect(address);
        this.clearSessionToken();
    };

    handleError = (error, clearSuggestions) => {
        clearSuggestions();
    };

    createSessionToken() {
        let searchOptions = this.state.searchOptions;
        if (searchOptions.sessionToken == null) {
            searchOptions.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
            this.setState({ searchOptions: searchOptions });
            let timeout = setTimeout(this.clearSessionToken, 180000);
            this.setState({ timeout: timeout });
        }
    }

    clearSessionToken() {
        let searchOptions = this.state.searchOptions;
        searchOptions.sessionToken = null;
        this.setState({ searchOptions: searchOptions });

        if (this.state.timeout != null) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
    }

    render() {
        return (
            <>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    shouldFetchSuggestions={this.state.address.length > 2}
                    searchOptions={this.state.searchOptions}
                    onError={this.handleError}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <div className={"autocomplete-input-container"}>
                            <input
                                {...getInputProps({
                                    placeholder:
                                        this.props.placeholder ??
                                        "Suche Adressen",
                                    className: "form-control",
                                    autoComplete: "nothing",
                                    type: this.props.type
                                })}
                            />
                            {this.state.address.length > 3 && (
                                <div
                                    className={
                                        "autocomplete-dropdown-container"
                                    }
                                >
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? {
                                                  backgroundColor: "#fafafa",
                                                  cursor: "pointer",
                                              }
                                            : {
                                                  backgroundColor: "#ffffff",
                                                  cursor: "pointer",
                                              };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(
                                                    suggestion,
                                                    {
                                                        className,
                                                        style,
                                                    }
                                                )}
                                                key={suggestion.placeId}
                                            >
                                                <span>
                                                    {suggestion.description}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </PlacesAutocomplete>
                <div hidden={true} id="map"></div>
            </>
        );
    }
}

const placeDetails = (placeId) => {
    const placeService = new window.google.maps.places.PlacesService(
        document.createElement("div")
    );
    const OK = window.google.maps.places.PlacesServiceStatus.OK;
    return new Promise((resolve, reject) => {
        placeService.getDetails({ placeId }, (results, status) => {
            if (status !== OK) {
                reject(status);
            }
            resolve(results);
        });
    });
};
