import {inject} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {BulkOperationsRepository} from "../../bulk-operations/bulk-operations-repository";

@inject(
    BulkOperationsRepository
)
export class ExecuteBulkOperationActionHandler extends ActionHandlerInterface
{
    constructor(
        bulkOperationsRepository
    ) {
        super();

        this.bulkOperationsRepository = bulkOperationsRepository;
    }

    getActionType()
    {
        return 'bulk-operation';
    }

    getReturnContext(config, context)
    {
        if (!config.operationConfig || !config.operationConfig.type) {
            throw new Error("You need to specify operationConfig.type in action config");
        }

        return Object.assign(
            {bulk: false}, // do not duplicate at the moment, @todo replace all this by bulk actions
            config,
            context
        );
    }

    getAction(returnContext)
    {
        return () => {
            this.bulkOperationsRepository.execute(returnContext.operationConfig.type, returnContext);
        };
    }
}
