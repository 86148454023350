import {inject} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {AuthTokenStorage} from "../../auth/auth-token-storage";
import {Client} from "../../api/client";

@inject(
    Client,
    AuthTokenStorage
)
export class OpenUrlActionHandler extends ActionHandlerInterface
{
    constructor(
        client,
        authTokenStorage
    ) {
        super();

        this.client = client;
        this.authTokenStorage = authTokenStorage;
    }

    getActionType()
    {
        return 'openURL';
    }

    // noinspection JSUnusedLocalSymbols
    getReturnContext(config, context)
    {
        let url = config.url;

        if (!url.match(/^(http|https|mailto):/)) {
            url = this.client.client.baseUrl + url + '?access_token=' + this.authTokenStorage.getAccessToken();
        }

        return {url: url, bulk: false};
    }

    getAction(returnContext)
    {
        return () => {
            location.href = returnContext.url;
        };
    }
}
