import {inject, LogManager} from "aurelia-framework";
import * as _ from "lodash";
import {RequestCallbacks} from "./request-callbacks";
import {ActionHandlerInterface} from "./action-handler.interface";
import {Client} from "../../api/client";
import {FlashService} from "../../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";
import {I18N} from 'aurelia-i18n';

const logger = LogManager.getLogger('action-handler');

@inject(
    Client,
    FlashService,
    RequestCallbacks,
    EventAggregator,
    I18N
)
export class RequestActionHandler extends ActionHandlerInterface
{
    constructor(
        client,
        flash,
        requestCallbacks,
        ea,
        i18n
    ) {
        super();

        this.ea = ea;
        this.client = client;
        this.flash = flash;
        this.requestCallbacks = requestCallbacks;
        this.i18n = i18n;
    }

    getActionType()
    {
        return 'request';
    }

    static _buildBody(returnContext) {
        let body;

        if (_.isFunction(returnContext.body)) {
            body = returnContext.body();
        } else {
            body = returnContext.body;

            if (returnContext.bulk && null == body) {
                body = {'selection': returnContext.selectedItems};
            }
        }

        return body;
    }

    static _buildUrl(returnContext) {
        return returnContext.url.replace(/{id}/g, returnContext.id);
    }

    getReturnContext(config, context)
    {
        let returnContext = Object.assign(
            {
                method: config.method,
                url: config.url,
                body: config.body || null,
                expiry: config.expiry || null,
                callback: null,
                refreshEntity: config.refreshEntity || null,
                successMessage: config.successMessage || null,
                bulk: config.bulk || false
            },
            context
        );

        if (config.callback && this.requestCallbacks.isValidCallback(config.callback)) {
            returnContext.callback = config.callback;
        }

        return returnContext;
    }

    getAction(returnContext)
    {
        return () => {
            let body = RequestActionHandler._buildBody(returnContext);
            let url = RequestActionHandler._buildUrl(returnContext);

            // noinspection JSCheckFunctionSignatures
            logger.debug('PERFORM REQUEST', {
                'method': returnContext.method,
                'url': url,
                'body': body,
                'expiry': returnContext.expiry,
            }, returnContext);

            return this.client.request(
                returnContext.method,
                url,
                body,
                returnContext.expiry
            ).then(
                async (response) => {
                    this.requestCallbacks.executeCallback(returnContext.callback, response.data);

                    if (null != returnContext.refreshEntity) {
                        this.ea.publish('sio_form_post_submit', {config: {modelId: returnContext.refreshEntity}});
                    }

                    if (null != returnContext.successMessage) {
                        this.flash.success(this.i18n.tr(returnContext.successMessage));
                    }
                }
            ).catch(
                async (response) => {
                    this.flash.error(response.data.message);
                }
            );
        }
    }
}
