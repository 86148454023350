import {createSelector, createSlice} from "@reduxjs/toolkit"
import moment from "moment-timezone"
import {clearBookingsFulfilled} from "./accounting-api"
import {toggleSelection} from "../../utilities/toggle-selection"
import {selectAccountingStates} from "./state-slice"

const convertFromDate = date => date ? moment(date).format("YYYY-MM-DD") : undefined
const convertToDate = value => value ? new Date(value) : undefined

function calculateSums(state) {
    state.sum = (state.selected.length ? state.amounts.filter(([id]) => state.selected.includes(id)) : state.amounts)
        .reduce(([crSum, drSum], [_, cr, dr]) => [crSum + cr, drSum + dr], [0, 0])
    state.canClear = !!state.clearable.length && 1 < state.selected.length && state.sum[0] === state.sum[1]
    console.debug("calculateSums", state.amounts.length, state.sum)
}

const entriesSlice = createSlice({
    name: "accounting/entries",
    initialState: {
        context: undefined,
        combinedCrDr: true,
        search: "",
        from: undefined,
        to: undefined,
        date: "bookDate",
        organization: undefined,
        expandedBookings: [],
        clearable: [],
        selected: [],
        canClear: false,
        hasCleared: false,
        hasUncleared: false,
        showCleared: false,
        showUncleared: true
    },
    reducers: {
        setContext: {
            prepare: (context, additional) => ({payload: {context, ...additional}}),
            reducer(state, {
                payload: {
                    context, organization, stack, costObject,
                    reference, account, canDialogBooking
                }
            }) {
                if (state.context === context || !context) {
                    return
                }

                const today = new Date()

                switch (context) {
                    case "journal":
                        state.selectedStates = ["booked"]
                        state.sort ??= ["bookSequence", false]
                        state.showCleared = true
                        state.from ??= moment([today.getFullYear(), 0, 1]).format("YYYY-MM-DD")
                        state.to ??= moment([today.getFullYear(), 11, 31]).format("YYYY-MM-DD")
                        break

                    case "account":
                        state.selectedStates = ["booked"]
                        state.sort ??= ["bookDate", false]
                        state.showCleared = !false
                        state.selectedStates = ["unchecked", "checked", "booked"]
                        state.from ??= moment([today.getFullYear(), 0, 1]).format("YYYY-MM-DD")
                        state.to ??= moment([today.getFullYear(), 11, 31]).format("YYYY-MM-DD")
                        break

                    case "stack":
                        state.selectedStates = ["unchecked", "checked"]
                        break

                    default:
                        state.selectedStates = ["booked", "checked"]
                        state.showCleared = false
                }

                state.showUncleared = true
                state.context = context
                state.organization = organization
                state.search = ""
                state.stack = stack
                state.costObject = costObject
                state.reference = reference
                state.account = account
                state.canDialogBooking = canDialogBooking
                state.sort ??= ["bookDate", false]
                state.sum = [0, 0, "EUR"]
            }
        },
        setOrganization: {
            prepare: id => ({payload: id}),
            reducer(state, {payload: organization}) {
                state.organization = organization
                state.expandedBookings = []
            }
        },
        toggleCombinedCrDr: state => {
            state.combinedCrDr = !state.combinedCrDr
        },
        toggleShowCleared: state => {
            state.showCleared = !state.showCleared
        },
        toggleShowUncleared: state => {
            state.showUncleared = !state.showUncleared
        },
        toggleSelectedState: {
            prepare: id => ({payload: {id}}),
            reducer(state, {payload: {id}}) {
                state.selectedStates = toggleSelection(state.selectedStates, id)
            }
        },
        setSearch: {
            prepare: search => ({payload: search}),
            reducer(state, {payload: search}) {
                state.search = search
            }
        },
        setDate: {
            prepare: date => ({payload: date}),
            reducer(state, {payload: date}) {
                state.date = date
            }
        },
        setDateRange: {
            prepare: ([from, to]) => ({
                payload: {
                    from: convertFromDate(from),
                    to: convertFromDate(to)
                }
            }),
            reducer(state, {payload: {from, to}}) {
                state.from = from
                state.to = to
            }
        },
        toggleSort: {
            prepare: sort => ({payload: sort}),
            reducer(state, {payload: sort}) {
                if (state.sort[0] === sort) {
                    state.sort[1] = !state.sort[1]
                } else {
                    state.sort = [sort, true]
                }
            }
        },
        toggleExpandedBooking: {
            prepare: id => ({payload: id}),
            reducer(state, {payload: id}) {
                state.expandedBookings = toggleSelection(state.expandedBookings, id)
            }
        },
        clear: {
            reducer(state) {
                state.expandedBookings = []
                state.context = undefined
                state.search = ""
                state.stack = undefined
                state.costObject = undefined
                state.reference = undefined
                state.account = undefined
                state.clearable = []
                state.amounts = []
                state.sum = [0, 0]
                state.hasCleared = false
                state.canClear = false
                state.date = "bookDate"
            }
        },
        toggleSelect: {
            prepare: id => ({payload: id}),
            reducer: function toggleSelectReducer(state, {payload: id}) {
                state.selected = toggleSelection(state.selected, id)
                calculateSums(state)
            }
        },
        toggleSelectAll(state) {
            state.selected = state.selected.length === state.clearable.length ? [] : state.clearable;
            calculateSums(state)
        },
        update: {
            prepare: (amounts, currency, clearable, states, hasCleared, hasUncleared) => ({
                payload: {amounts, currency, clearable, states, hasCleared, hasUncleared}
            }),
            reducer(state, {payload: {amounts, currency, clearable, states, hasCleared, hasUncleared}}) {
                state.amounts = amounts
                state.currency = currency
                state.clearable = clearable
                state.states = states
                state.hasCleared = hasCleared
                state.hasUncleared = hasUncleared
                state.selected = state.selected.filter(booking => clearable.includes(booking))
                calculateSums(state)
            }
        }
    },
    extraReducers: builder => builder
        .addMatcher(clearBookingsFulfilled, state => {
            state.selected = []
        })
})

const entries = entriesSlice.reducer
const selectAccountingEntries = createSelector(state => state?.accounting?.entries, state => state)
const selectAdditionalQueryParams = createSelector(
    selectAccountingEntries,
    ({stack, costObject, reference, account}) => ({stack, costObject, reference, account})
)

export const {
    setContext,
    setSearch,
    setDate,
    setDateRange,
    toggleCombinedCrDr,
    toggleSelectedState,
    toggleSort,
    toggleExpandedBooking,
    toggleShowCleared,
    toggleShowUncleared,
    toggleSelect,
    toggleSelectAll,
    setOrganization,
    clear,
    update
} = entriesSlice.actions

export default entries

const selectStates = createSelector(selectAccountingEntries, ({states}) => states)
export const selectContext = createSelector(selectAccountingEntries, ({context}) => context)
export const selectOrganization = createSelector(selectAccountingEntries, ({organization}) => organization)
export const selectStack = createSelector(selectAccountingEntries, ({stack}) => stack)
export const selectSearch = createSelector(selectAccountingEntries, ({search}) => search)
export const selectSelectedStates = createSelector(selectAccountingEntries, ({selectedStates}) => selectedStates)
export const selectCombinedCrDr = createSelector(selectAccountingEntries, ({combinedCrDr}) => combinedCrDr)
export const selectCanClear = createSelector(selectAccountingEntries, ({canClear}) => canClear)
export const selectHasCleared = createSelector(selectAccountingEntries, ({hasCleared}) => hasCleared)
export const selectHasUncleared = createSelector(selectAccountingEntries, ({hasUncleared}) => hasUncleared)
export const selectShowCleared = createSelector(selectAccountingEntries, ({showCleared}) => showCleared)
export const selectShowUncleared = createSelector(selectAccountingEntries, ({showUncleared}) => showUncleared)
export const selectCanDialogBooking = createSelector(selectAccountingEntries, ({canDialogBooking}) => canDialogBooking)
export const selectClearable = createSelector(selectAccountingEntries, ({clearable}) => clearable)
export const selectSelected = createSelector(selectAccountingEntries, ({selected}) => selected)
export const selectExpandedBookings = createSelector(selectAccountingEntries, ({expandedBookings}) => expandedBookings)
export const selectDate = createSelector(selectAccountingEntries, ({date}) => date)
const selectFrom = createSelector(selectAccountingEntries, ({from}) => from)
const selectTo = createSelector(selectAccountingEntries, ({to}) => to)
export const selectDateRange = createSelector(selectFrom, selectTo, (from, to) => [convertToDate(from), convertToDate(to)])
export const selectSort = createSelector(selectAccountingEntries, ({sort}) => sort)
export const selectSum = createSelector(selectAccountingEntries, ({sum, currency}) => [...sum, currency])
export const selectAccount = createSelector(selectAccountingEntries, ({account}) => account)
export const selectQueryParams = createSelector(
    selectSelectedStates, selectSearch, selectDate, selectFrom, selectTo,
    selectSort, selectOrganization, selectAdditionalQueryParams,
    (states, search, date, from, to, sort, organization, additional) => ({
        states, search, date, from, to, organization,
        sort: sort ? [sort[0], sort[1] ? "asc" : "desc"] : undefined,
        ...additional
    })
)
export const selectUsedStates = createSelector(
    selectAccountingStates,
    selectStates,
    selectContext,
    (states, used = [], context) => Object.entries(states)
        .filter(([id, {contexts}]) => used.includes(id) && contexts.includes(context))
        .map(([id, state]) => ({id, ...state}))
)
