import React, {Fragment, useState} from "react"
import {Button, Drawer} from "rsuite"
import FieldHistory from "./field-history"

export function Field({field}) {
    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            <Button size="xs" onClick={() => setOpen(true)}>{field}</Button>
            <Drawer open={open} placement="bottom" onClose={() => setOpen(false)}>
                <Drawer.Header>
                    <Drawer.Title>{field}</Drawer.Title>
                </Drawer.Header>

                <Drawer.Body>
                    <FieldHistory field={field}/>
                </Drawer.Body>
            </Drawer>
        </Fragment>
    )
}
export default Field
