import {customElement, bindable, inject} from 'aurelia-framework';
import {EventAggregator} from "aurelia-event-aggregator";
import {UserClient} from "../api/user-client";

@customElement('sio-activity-viewer')
@inject(EventAggregator, UserClient)
export class ActivityViewer
{
    @bindable identifier
    @bindable objectId;
    @bindable type;

    users = [];

    constructor(ea, userClient)
    {
        this.ea = ea;
        this.userClient = userClient;
    }

    attached()
    {
        this.subscription = this.ea.subscribe('sio_activity', (response) => {

            if (response.key === this.type + '/' + this.identifier + '/' + this.objectId) {
                this.users = response.users.filter((user) => {
                    return user.id !== this.userClient.user.id;
                });

                if (this.timeoutHandle) {
                    window.clearTimeout(this.timeoutHandle);
                }

                this.timeoutHandle = window.setTimeout(() => {
                    this.users = [];
                }, 300000);
            }
        });
    }

    detached()
    {
        if (this.timeoutHandle) {
            window.clearTimeout(this.timeoutHandle);
        }

        if (this.subscription) {
            this.subscription.dispose();
        }
    }
}
