import {customElement, bindable} from "aurelia-framework";
import {createElement} from "react";
import {render, unmountComponentAtNode} from "react-dom";
import {AnnotationViewerReact} from "./annotation-viewer-react";

@customElement('sio-annotation-viewer')
export class Address {
    @bindable file;
    @bindable predictions;

    container;

    detached() {
        this.container && unmountComponentAtNode(this.container);
    }

    async bind() {

        render(
            createElement(
                AnnotationViewerReact,
                {
                    predictions: this.predictions,
                    file: this.file,
                }
            ),
            this.container
        );

    }
}
